import { Box, Typography } from "@mui/material";
import FilterItem from "./FilterItem";
import { createElement } from "react";

const FilterGroup = ({ group, onChange, activeFilters }) => {
  //console.log("FITLER", activeFilters);
  return (
    <Box className="flex flex-col">
      <Typography variant="h6" className="uppercase">
        {group.label}
      </Typography>
      <Box className="flex flex-col  pt-2 gap-2">
        {group.items.map((filter, index) => {
          const activeFilter = activeFilters.find(
            (obj) => obj.key === filter.key
          );

          return createElement(FilterItem, {
            item: activeFilter || filter,
            onChange,
            key: index,
          });
        })}
      </Box>
    </Box>
  );
};

export default FilterGroup;
