import { useEffect, useState } from "react";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../api/api";
import Feedback from "../special/Feedback";
import ExoForm from "../exo/ExoForm";
import Loader from "../special/Loader";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ExoPaymentRequest from "../exo/ExoPaymentRequest.tsx";
import ExoDialog from "../exo/ExoDialog.jsx";
import { Typography } from "@mui/material";
import moment from "moment";

const NewUser = ({ onClose = () => {}, onCreate = () => {}, startData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const config = useConfig();
  const { t } = useTranslation();
  const defaultStartData = { color: "#5e9ac8" };
  const [startUpData, setStartUpData] = useState(defaultStartData);

  const [openPayment, setOpenPayment] = useState(null);

  useEffect(() => {
    if (startData) setStartUpData(startData);
  }, [startData]);

  function handleSubmit(data) {
    // interval
    const aboDurationMap = {
      monthly: 1,
      annually: 12,
      biannually: 24,
    };

    const endDate = moment()
      .add(aboDurationMap[data.aboDuration], "M")
      .format(config.general.dateOutputFormat);

    // items
    var items = [];

    data.accessFeatures?.forEach((feature) => {
      if (feature.availableSeats <= 0) {
        const name = `${t(feature.label)} - ${t("Valid Until")} ${endDate}`;
        items.push({
          name: name,
          description: (
            <Typography variant="h6" fontSize="12px">
              {t(feature.description)}
            </Typography>
          ),
          amount: 1,
          taxRate: 19,
          price:
            parseFloat(feature.prices[data.aboDuration].price) *
            aboDurationMap[data.aboDuration],
        });

        // calc discount
        if (feature.prices[data.aboDuration].discount) {
          const discount = feature.prices[data.aboDuration].discount;

          items.push({
            name: discount.label,
            description: (
              <Typography variant="h6" fontSize="12px">
                {discount.description}
              </Typography>
            ),
            amount: 1,
            taxRate: 19,
            price:
              parseFloat(discount.price) * aboDurationMap[data.aboDuration] -
              parseFloat(feature.prices[data.aboDuration].price) *
                aboDurationMap[data.aboDuration],
          });
        }
      }
    });

    if (items.length === 0) return handleRequest(data);
    setOpenPayment({ data, items });
  }

  function handleRequest(data) {
    setOpenPayment(null);
    DBRequest({
      config,
      path: startData && startData.id ? "users/" + startData.id : "users",
      method:
        startData && startData.id
          ? API_PATCH({ ...data, termsAccepted: true })
          : API_POST({ ...data, termsAccepted: true }),
      onFeedback: handleFeedback,
      onResponse: handleResponse,
      onLoading: setIsLoading,
    });
  }

  function handleResponse(res) {
    onCreate(res.data);
    onClose();
  }

  const fields = [
    {
      label: `${t("Color")} (${t("optional")})`,
      type: "color",
      key: "color",
    },
    {
      label: `${t("Profile Picture")} (${t("optional")})`,
      type: "profilePicture",
      key: "picture",
    },
    {
      label: t("Access features"),
      type: "accessFeatures",
      key: "accessFeatures",
    },
    {
      label: t("Salutation"),
      type: "text",
      key: "salutation",
    },
    {
      label: t("First Name"),
      type: "text",
      key: "firstName",
    },
    {
      label: t("Last Name"),
      type: "text",
      key: "lastName",
    },
    {
      label: t("Mail"),
      type: "text",
      key: "email",
    },
    {
      label: t("Phone"),
      type: "text",
      key: "phone",
    },
    {
      label: t("Is CEO"),
      type: "checkbox",
      key: "isCeo",
    },
  ];

  const validationSchema = Yup.object().shape({
    salutation: Yup.string().required("Salutation is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
  });

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleFeedback(text, type) {
    setIsLoading(false);
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
    if (type === "success") onClose();
  }
  return (
    <>
      <ExoForm
        draftType="user"
        titleFunction={(row) => {
          return row.firstName + " " + row.lastName;
        }}
        startDataCollection={startUpData}
        header={startData ? t("Edit Employee") : t("New Employee")}
        fields={fields}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        onCancle={onClose}
        alert={
          startData
            ? {}
            : {
                type: "info",
                message: t(
                  "The new user receives an e-mail to the specified e-mail address with which they can set a password."
                ),
              }
        }
      />
      <ExoDialog
        limitWidth
        open={Boolean(openPayment)}
        onClose={() => setOpenPayment(null)}
        onSubmit={() => handleRequest(openPayment)}
      >
        {openPayment?.items && (
          <ExoPaymentRequest
            items={openPayment?.items}
            onSubmit={() => handleRequest(openPayment.data)}
            onCancle={() => setOpenPayment(null)}
          />
        )}
      </ExoDialog>

      <Feedback setState={setAlertState} state={alertState} />
      <Loader active={isLoading} message={t("Sending Data...")} />
    </>
  );
};

export default NewUser;
