import { useState } from 'react';
import TemplatePreview from '../../../components/templates/TemplatePreview';
import {
  Badge,
  Box,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../global/theme/tokens';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ContactsIcon from '@mui/icons-material/Contacts';

import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

export const TemplateGroup = ({ group, onSelect, label }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const formats = Object.keys(group);

  const [format, setFormat] = useState(formats[0]);

  const templateFormatMap = {
    A4: t('PDF'),
    Mail: t('E-Mail'),
    Web: t('Web'),
  };

  const variantIcon = {
    company: { Icon: ApartmentIcon, color: colors.info },
    contact: { Icon: ContactsIcon, color: colors.success },
  };

  return (
    <Box className="flex flex-col pb-6">
      <Typography variant="h4" className="pb-2">
        {label}
      </Typography>
      <ToggleButtonGroup
        size="small"
        value={format}
        exclusive
        onChange={(e) => setFormat(e.target.value)}
        aria-label="Template Group"
      >
        {formats.map((key) => (
          <ToggleButton value={key}>{templateFormatMap[key]}</ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Box className="flex flex-wrap gap-4">
        {group[format]?.map((template) => (
          <Box className="relative z-0 pt-2">
            <Box
              onClick={() => onSelect(template.id)}
              className="w-[300px] h-[423px] rounded-lg overflow-hidden flex z-0"
              sx={{
                border: `1px solid ${
                  variantIcon[template.variant]?.color || colors.selected
                }`,
              }}
            >
              <TemplatePreview
                data={template}
                fit={true}
                background={true}
                paginate={false}
                edit={true}
              />
              <Typography
                className="z-10 absolute translate-y-1/3 bottom-0 rounded-lg px-3 py-1 left-4 right-4 "
                textAlign="center"
                sx={{
                  bgcolor:
                    variantIcon[template.variant]?.color || colors.selected,
                }}
              >
                {template.name}
              </Typography>
              <VariantIcon variant={variantIcon[template.variant]} />
            </Box>
            {template.mailTemplateId && (
              <IconButton
                sx={{ position: 'absolute' }}
                className="top-2 right-2 z-10"
                onClick={() => onSelect(template.mailTemplateId)}
              >
                <ForwardToInboxIcon />
              </IconButton>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const VariantIcon = ({ variant }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  if (!variant) return;
  const { Icon, color } = variant;

  return (
    <Box className="absolute top-0 right-0 w-full h-full flex items-center justify-center z-10">
      <Icon sx={{ color: color + '50', fontSize: '90px' }} />
    </Box>
  );
};
