import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { WPEContainer } from "../WPEContainer";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { WPEToDoElement } from "../../../../../components/exo/project/work-package-elements/WPEToDoElement";
import { useEffect, useState } from "react";
import useFetch from "../../../../../api/useFetch";
import { updateOrCreate } from "../../../../../components/special/updateOrCreate";
import { useTranslation } from "react-i18next";

export const WPETypeToDoList = ({ element, onChange, onDelete }) => {
  const { t } = useTranslation();

  const { apiData, setApiData } = useFetch(
    `work-packages/elements/list/${element.id}`
  );

  const [isCheckedVisible, setisCheckedVisible] = useState(false);
  const [hoverText, setHoverText] = useState("Hide completed tasks");

  const [completedCount, setCompletedCount] = useState(0);

  function updateCompletedCount(tasks) {
    tasks = tasks.filter((element) => element.status != null);
    setCompletedCount(tasks.reduce((sum, element) => sum + element.status, 0));
  }

  useEffect(() => {
    if (apiData) updateCompletedCount(apiData.data.tasks);
  }, [apiData]);

  function handleToggleVisibility() {
    setisCheckedVisible(!isCheckedVisible);
    if (isCheckedVisible) {
      setHoverText("Show completed tasks");
    } else setHoverText("Hide completed tasks");
  }

  function handleAddToDo() {
    setApiData({
      ...apiData,
      data: { ...apiData.data, tasks: [{}, ...apiData.data.tasks] },
    });
  }

  function handleChange(updatedTask) {
    const updatedTasks = updateOrCreate(apiData.data.tasks, updatedTask);
    const dbTasks = updatedTasks.filter((obj) => obj.id);

    setApiData({ ...apiData, data: { ...apiData.data, tasks: dbTasks } });
  }

  function handleDelete(element) {
    const dbTasks = apiData.data.tasks.filter((obj) => obj.id != element.id);
    setApiData({ ...apiData, data: { ...apiData.data, tasks: dbTasks } });
  }

  return (
    <Box className="relative">
      <Box
        className="top-1 right-1 z-10 flex gap-5"
        sx={{ position: "absolute" }}
      >
        <IconButton onClick={handleAddToDo}>
          <AddCircleIcon />
        </IconButton>
      </Box>
      <WPEContainer
        title={element.name}
        className="h-full w-full flex flex-col gap-2"
        sx={{ minHeight: "100px" }}
      >
        <Box className="flex items-center">
          <Tooltip title={t(hoverText)}>
            <Button onClick={handleToggleVisibility}>
              <Typography fontSize={12}>
                {isCheckedVisible ? (
                  <VisibilityOffIcon fontSize="small" />
                ) : (
                  <VisibilityIcon fontSize="small" />
                )}
                {` ${completedCount} ${t("completed Tasks")}`}
              </Typography>
            </Button>
          </Tooltip>
        </Box>
        {apiData?.data.tasks.map((task) => (
          <WPEToDoElement
            key={task.id}
            element={task}
            onChange={handleChange}
            wpeId={element.id}
            onDelete={handleDelete}
            className={!isCheckedVisible ? (!task.status ? "" : "hidden") : ""}
          />
        ))}
      </WPEContainer>
    </Box>
  );
};
