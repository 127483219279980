import React, { useState } from "react";
import ExoDialog from "../../components/exo/ExoDialog";
import ExoForm from "../../components/exo/ExoForm";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../api/api";
import { ProjectAddElementButton } from "../../components/exo/project/ProjectAddElementButton";
import * as Yup from "yup";

export const NewTransaction = ({
  onCreate,
  bankAccount,
  startData,
  onClose,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const config = useConfig();

  const fields = [
    {
      label: t("Target"),
      key: "transactionPartnerName",
      type: "text",
    },
    {
      label: t("Intended Use"),
      key: "intendedUse",
      type: "text",
    },
    {
      label: t("Amount"),
      key: "amount",
      type: "money",
    },
    {
      label: t("Booking Date"),
      key: "bookingDate",
      type: "date",
    },
  ];

  const validationSchema = Yup.object().shape({
    transactionPartnerName: Yup.string().required(t("Target is required")),
    intendedUse: Yup.string().nullable(), // Optional field
    amount: Yup.number().required(t("Amount is required")),
    bookingDate: Yup.date().required(t("Booking Date is required")),
  });

  function handleSubmit(data) {
    const reqData = {
      ...data,
      bookingText: parseFloat(data.amount) > 0 ? "deposit" : "payout",
      valueDate: data.bookingDate,
      ibanOrderAccount: bankAccount.iban,
    };

    DBRequest({
      config,
      path: startData ? `transactions/${startData.id}` : `transactions`,
      method: startData ? API_PATCH(reqData) : API_POST(reqData),
      onResponse: handleResponse,
    });
  }

  function handleResponse(res) {
    setOpen(false);
    onCreate(res);
  }

  function handleClose() {
    setOpen(false);
    onClose();
  }

  return (
    <>
      <ProjectAddElementButton onClick={() => setOpen(true)}>
        <AddIcon />
      </ProjectAddElementButton>

      <ExoDialog limitWidth open={open || startData} onClose={handleClose}>
        <ExoForm
          header={t("New Transaction")}
          startDataCollection={startData}
          noDifferences
          fields={fields}
          onCancle={handleClose}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        />
      </ExoDialog>
    </>
  );
};
