import { FormControlLabel, Switch } from "@mui/material";

const FilterItemToggle = ({ item, onChange }) => {
  function handleChange(e) {
    onChange({ ...item, active: !item.active, value: item.baseValue });
  }
  return (
    <FormControlLabel
      control={<Switch onChange={handleChange} checked={item.active} />}
      label={item.label}
    />
  );
};

export default FilterItemToggle;
