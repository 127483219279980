import { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { renderHours } from "./worktime";
import RadialChart from "../../components/charts/RadialChart";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";
import { TimeAccountCard } from "./TimeAccountCard";
import { useTimeTracking } from "../../components/time-tracking/AppTimeProvider";

const TimeDashboard = ({ timeTrackingUser, isLoading }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const { minutesToday, workCompletionPercentage, timeAccount, secondsIn } =
    useTimeTracking();

  const [chartData, setChartData] = useState(null);
  useEffect(() => {
    if (!timeTrackingUser) return;
    setChartData(
      timeTrackingUser.projectsToday
        ? Array({
            data: timeTrackingUser.projectsToday,
            id: "today",
          })
        : []
    );
  }, [timeTrackingUser]);

  return (
    <Box
      className="z-10 p-4 mb-4 rounded-xl grid grid-cols-2 lg:grid-cols-3 backdrop-blur-lg"
      style={{ backgroundColor: colors.glass }}
    >
      <Box className="flex justify-center items-center col-span-1 row-start-2 lg:row-start-1">
        <Box
          className="w-36 md:w-60 aspect-square flex justify-center items-center overflow-hidden"
          sx={{
            ".MuiSkeleton-root": {
              height: "100%",
            },
          }}
        >
          <CircularProgressbarWithChildren
            value={workCompletionPercentage || 0}
            maxValue={1}
            styles={{
              path: {
                stroke: colors.greenAccent[400],
              },
              trail: {
                stroke: "rgba(0, 0, 0, 0.2)",
                strokeLinecap: "butt",
                transform: "rotate(0.25turn)",
                transformOrigin: "center center",
              },
              text: {
                fill: colors.grey[100],
                fontSize: "16px",
              },
            }}
          >
            <Box className="flex flex-col">
              <Box className="text-2xl md:text-3xl text-center">
                {renderHours(minutesToday)} h
              </Box>
              <Typography variant="h6" textAlign="center">
                {t("Worked Time")}
              </Typography>
            </Box>
          </CircularProgressbarWithChildren>
        </Box>
      </Box>
      <Box
        className="flex justify-center items-center flex-col col-span-3 row-start-1  lg:col-span-1 pb-8 md:pb-0 lg:row-start-1"
        sx={{ minWidth: "250px" }}
      >
        <TimeAccountCard
          minutes={timeAccount}
          isLoading={!timeAccount}
          label={t("Time Account")}
        />
      </Box>
      <Box
        className="flex justify-center items-center flex-col col-span-1 row-start-2 lg:row-start-1"
        sx={{
          ".MuiSkeleton-root": {
            height: "100%",
          },
        }}
      >
        <Box className="relative aspect-square w-40 md:w-64 w-max-[15rem] ">
          {!chartData ? (
            <Skeleton variant="circular" />
          ) : (
            <>
              <Box className=" z-10 w-full aspect-square absolute top-0 left-0 flex justify-center items-center flex-col">
                <Box className="text-xl md:text-3xl text-center">
                  {renderHours(Math.round(secondsIn / 60))} h
                </Box>
                <Typography variant="h6" textAlign="center">
                  {t("In Project")}
                </Typography>
              </Box>
              <RadialChart data={chartData} />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TimeDashboard;
