export function formatIBAN(iban) {
  if (!iban) {
    return "";
  }
  // Remove any spaces from the IBAN
  iban = iban.replace(/\s/g, "");

  // Check if the IBAN is valid (contains only alphanumeric characters and is of the correct length)
  if (!/^[A-Z0-9]+$/.test(iban)) {
    return "";
  }

  // Split the IBAN into groups of 4 characters
  var formattedIBAN = iban.match(/.{1,4}/g).join(" ");

  return formattedIBAN;
}

export function replaceSpaces(inputString, replacement) {
  if (!inputString) {
    return "";
  }
  // Use a regular expression to replace all spaces with the specified replacement character
  return inputString.replace(/\s/g, replacement);
}
