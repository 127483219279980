import FilterItemDropdown from "./FilterItemDropdown";
import FilterItemToggle from "./FilterItemToggle";

const FilterItem = ({ item, onChange, checked }) => {
  if (item.type == "toggle")
    return (
      <FilterItemToggle item={item} onChange={onChange} checked={checked} />
    );
  if (item.type == "dropdown")
    return (
      <FilterItemDropdown item={item} onChange={onChange} checked={checked} />
    );
};

export default FilterItem;
