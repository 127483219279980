import { Box, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import ExoTable from "../../components/exo/ExoTable";
import useFetch from "../../api/useFetch";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import Header from "../../components/special/Header";
import ExoTableRecipient from "../../components/exo/ExoTableRecipient";
import MoneyLabel from "../../components/label/MoneyLabel";
import { PaymentReminderStatus } from "../../components/special/Status";
import ExtendPaymentReminder from "./ExtendPaymentReminder";
import { useNavigate } from "react-router-dom";
import { recipientTypeMap } from "../../components/routing/routingMaps";
import { useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";

const PaymentReminder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const config = useConfig();

  const [filter, setFilter] = useState("status[ne]=3");

  const { apiData, setApiData } = useFetch("dunning-processes?status[ne]=3");

  const Columns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.invoice.nr}</Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <PaymentReminderStatus number={row.status} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Title"),
      key: "title",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "100%",
      hiddenMobile: true,
      cell: ({ row }) => row.invoice.title, // custom xml for cells in the column. you get the row as object
      onClick: (row) => navigate(`/payment-reminders/${row.id}`),
    },
    {
      header: t("Client"),
      key: "recipient",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "270px",
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      cell: ({ row }) => <ExoTableRecipient row={row.invoice} />, // custom xml for cells in the column. you get the row as object
      onClick: (row) =>
        navigate(
          `/${recipientTypeMap[row.invoice.recipientType]}/${
            row.invoice.recipient.id
          }`
        ),
    },
    {
      header: t("Invoice Sum"),
      key: "amount",
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      className: "w-full lg:w-auto",
      cell: ({ row }) => <MoneyLabel money={row.invoice.amount} />,
      sortable: true, // enable sort function for column
    },
  ];

  const dunningFilters = {
    filter: {
      label: t("Filter"),
      items: [
        {
          type: "dropdown",
          label: t("Status"),
          key: "state2",
          baseValue: "status[eq]=",
          active: false,
          options: [
            {
              label: t("Ready"),
              value: 0,
            },
            {
              label: t("Running"),
              value: 1,
            },
            {
              label: t("Paused"),
              value: 2,
            },
            {
              label: t("Refusal"),
              value: 4,
            },
          ],
        },
      ],
    },
  };

  const [filters, setFilters] = useState(dunningFilters);

  function requestTableData(newFilter) {
    setApiData(null);
    DBRequest({
      config,
      path: `dunning-processes?${newFilter}`,
      method: API_GET,
      onResponse: setApiData,
    });
  }

  function handleChangeCategory(e) {
    const newFilter = e.target.value;
    if (filter == newFilter) {
      return;
    }
    if (newFilter === "status[ne]=3") setFilters(dunningFilters);
    if (newFilter === "status[eq]=3") setFilters(null);
    requestTableData(newFilter);
    setFilter(newFilter);
  }

  return (
    <>
      <Box className="flex gap-0 md:gap-8 md:items-center justify-between md:justify-start pr-2 md:flex-row flex-col pb-4 md:pb-0">
        <Header title={t("Payment Reminder")} />

        <ToggleButtonGroup
          size="small"
          value={filter}
          exclusive
          onChange={handleChangeCategory}
          aria-label="Selected Table"
          className="backdrop-blur-lg"
        >
          <ToggleButton value="status[ne]=3">{t("Running")}</ToggleButton>
          <ToggleButton value="status[eq]=3">{t("Finished")}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <ExoTable
        data={apiData}
        key={filter}
        isLoading={Boolean(!apiData)}
        columns={Columns}
        extendElement={({ row, onUpdate, onDelete }) => (
          <ExtendPaymentReminder
            row={row}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )}
        filters={filters}
      />
    </>
  );
};

export default PaymentReminder;
