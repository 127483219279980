import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../api/api";
import useFetch from "../../api/useFetch";
import CardGrid from "../../components/cards/layout/CardGrid";
import CardContainer from "../../components/cards/layout/CardContainer";
import StyledTextContainer from "../../components/preview/StyledTextContainer";
import RenderHTML from "../../components/preview/RenderHTML";
import SkeletonCard from "../../components/cards/SkeletonCard";
import HtmlRenderer from "../../components/preview/HtmlRenderer";
import InfoGridCard from "../../components/cards/PictureNameCards/InfoGridCard";
import { useEffect, useState } from "react";

export const ExtendMail = ({ row, onUpdate, onDelete, onEdit }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const { apiData, setApiData } = useFetch(`maillogs/${row.id}`);

  const initialInfos = [
    {
      label: t("Receiver"),
      value: apiData?.data.receiver,
    },
  ];

  const [infos, setinfos] = useState(initialInfos);

  useEffect(() => {
    let newInfos = initialInfos;

    apiData?.data.cc?.forEach((cc, index) => {
      const label = index == 0 ? "CC" : "";
      newInfos.push({ label: label, value: cc.address });
    });

    setinfos(newInfos);
  }, [apiData?.data]);

  return (
    <Box className="flex flex-col gap-4 relative overflow-y-auto">
      {!apiData ? (
        <MailSkeleton />
      ) : (
        <CardGrid>
          <InfoGridCard span={2} infos={infos} />

          <CardContainer
            span={999}
            sx={{ backgroundColor: "white", height: "800px" }}
          >
            <HtmlRenderer htmlString={apiData.data.htmlMail} />
          </CardContainer>
        </CardGrid>
      )}
    </Box>
  );
};

const MailSkeleton = () => {
  return (
    <CardGrid>
      <SkeletonCard height={52} span={4} />
      <SkeletonCard height={191} />
      <SkeletonCard height={191} />
      <SkeletonCard height={191} />
      <SkeletonCard height={191} />
      <SkeletonCard height={156} span={4} />
    </CardGrid>
  );
};
