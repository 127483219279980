import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import { countries } from 'country-data';

export const TypeCountry = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
}) => {
  function handleChange(value) {
    updateValidationOnChange(
      field.key,
      { [field.key]: countries[value].name, countryCode: value },
      true
    );
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="label-mui-country-field">{field.label}</InputLabel>
      <CountryDropdown
        value={dataCollection.countryCode}
        id="mui-country-field"
        onChange={handleChange}
        customRender={customRender}
        valueType="short"
        customProps={{
          labelId: 'label-mui-country-field',
          label: 'Country',
        }}
      />
    </FormControl>
  );
};

const customRender = ({ options, customProps, ...selectProps }) => (
  <Select {...selectProps} {...customProps}>
    {options.map(({ label, value, key }) => (
      <MenuItem value={value} key={key}>
        {label}
      </MenuItem>
    ))}
  </Select>
);
