import { useTheme, Box, Button, Typography } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import DateLabel from "../label/DateLabel";

/*
entries = [
{
    date: "2020-05-20",
    label: "test",
    isActive: false,
    data: {}, 
}
]
*/

const ExoHistory = ({ entries, EntryNode }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      className="flex flex-col w-full h-full overflow-y-auto"
      sx={{
        td: {
          padding: 0,
          verticalAlign: "top",
        },
      }}
    >
      <table className="w-full">
        <tbody>
          {entries.map(({ data, date, isActive, label }, index) => (
            <tr key={index}>
              <td>
                <Box className="py-[20px] pr-6 sticky top-0">
                  <Typography variant="h6" fontWeight={600}>
                    {label}
                  </Typography>
                  <Typography variant="h6">
                    <DateLabel date={date} />
                  </Typography>
                </Box>
              </td>
              <td className="w-full">
                <Box
                  className="flex w-full justify-start"
                  sx={{
                    borderColor: isActive ? colors.success : colors.selected,
                    borderLeftWidth: "5px",
                    borderStyle: "solid",
                    borderRadius: 0,
                    padding: "20px 0px 20px 24px",
                  }}
                >
                  <EntryNode key={data.id + index} data={data} />
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default ExoHistory;
