import { Box } from "@mui/material";
import { cardBreakPoints } from "./breakPoints";

const CardGrid = ({ children, className }) => {
  const gridSx = calcSx();

  function calcSx() {
    var newSx = {};
    cardBreakPoints.forEach((breakPoint) => {
      newSx[`@media (min-width: ${breakPoint.size}px)`] = {
        gridTemplateColumns: `repeat(${breakPoint.cols}, minmax(0, 1fr))`,
      };
    });
    return newSx;
  }

  return (
    <Box className={`w-full`}>
      <Box
        className={`rounded-lg grid gap-4 relative w-full ${className ?? ""}`}
        sx={{
          ...gridSx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CardGrid;
