import { useEffect } from "react";
import SelectDelivery from "../select/SelectDelivery";

const StepDelivery = ({
  updateDataCollection,
  dataCollection,
  updateValidation,
  useInvoiceMail,
  recipientKey,
  defaultRecipientMailKey,
  allowMailContent,
}) => {
  function handleDeliverySelect(delivery) {
    updateDataCollection({ delivery: delivery });
  }

  // validation
  useEffect(() => {
    var isValid = true;

    if (!dataCollection.delivery) isValid = false;

    if (
      dataCollection.delivery &&
      !dataCollection.delivery.saveOnly &&
      !dataCollection.delivery.deliveryMail
    )
      isValid = false;

    updateValidation(isValid);
  }, [dataCollection]);

  return (
    <SelectDelivery
      useInvoiceMail={useInvoiceMail}
      onSelect={handleDeliverySelect}
      recipient={dataCollection[recipientKey || "recipient"]}
      allowCC
      allowMailContent={allowMailContent}
      defaultRecipientMailKey={defaultRecipientMailKey}
      deliveryObj={dataCollection.delivery}
    />
  );
};

export default StepDelivery;
