import { useState } from 'react';
import DBSearch from '../../special/DBSearch';
import { Box, Paper, Popper, Typography, useTheme } from '@mui/material';
import TypeText from './TypeText';
import { tokens } from '../../../global/theme/tokens';

export const TypeAutoComplete = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [searchResults, setSearchResults] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'search-popper' : undefined;

  function handleUpdateTextfield(key, string) {
    updateValidationOnChange(field.key, { name: string });
  }

  return (
    <>
      <TypeText
        field={field}
        dataCollection={{ [field.key]: dataCollection[field.key]?.name }}
        errors={errors}
        updateValidationOnChange={handleUpdateTextfield}
        onFocus={handleClick}
        onBlur={() => setAnchorEl(null)}
      />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        sx={{ bgcolor: colors.glass, zIndex: 9999 }}
        placement="bottom-start"
      >
        <Paper className="flex flex-col h-[200px] overflow-x-hidden">
          <DBSearch
            param={field.searchParam}
            onSearch={setSearchResults}
            value={dataCollection[field.key]?.name}
            hideSearchBar
          >
            {searchResults &&
              searchResults.map((element) => (
                <Box
                  sx={{
                    border: `1px solid transparent`,
                    ':hover': {
                      border: `1px solid ${colors.info}`,
                      bgcolor: colors.info + '30',
                    },
                  }}
                  className=" rounded-md transition-all cursor-pointer min-w-[400px] max-w-full w-full px-2 py-1 flex items-center gap-4"
                  onMouseDown={() =>
                    updateValidationOnChange(field.key, {
                      name: element.label,
                    })
                  }
                >
                  <Typography className=" opacity-50 monospace">
                    {element.subLabel}
                  </Typography>
                  <Typography fontWeight={600}>{element.label}</Typography>
                </Box>
              ))}
          </DBSearch>
        </Paper>
      </Popper>
    </>
  );
};
