import { API_PATCH, API_POST, DBRequest, useConfig } from "../../api/api";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import DraftIndicator from "./draft/DraftIndicator";
import { Box } from "@mui/material";
import DraftHistory from "./draft/DraftHistory";
import findDifferences from "./findDifferencesInDataCollection";

const ExoDraftManager = ({
  dataCollection,
  draftType,
  className,
  sx,
  onChange,
  setDataCollection,
  startDataCollection,
  titleFunction,
}) => {
  const config = useConfig();

  const [openSelector, setOpenSelector] = useState(false);
  const [draftIsSaved, setDraftIsSaved] = useState(true);
  const [debouncedDataCollection] = useDebounce(dataCollection, 3000);

  useEffect(() => {
    if (
      !draftType ||
      Object.keys(findDifferences(startDataCollection, dataCollection, false))
        .length === 0
    )
      return;
    updateDraft();
  }, [debouncedDataCollection]);

  useEffect(() => {
    if (
      draftIsSaved &&
      Object.keys(findDifferences(startDataCollection, dataCollection, false))
        .length !== 0
    )
      setDraftIsSaved(false);
  }, [dataCollection]);

  function createDraft() {
    DBRequest({
      config,
      path: "drafts",
      method: API_POST({ dataCollection, type: draftType }),
      onResponse: (res) => onChange({ draftId: res.data.id }),
      onLoading: (value) => setDraftIsSaved(!value),
    });
  }

  function updateDraft() {
    // create draft if there is no draft at the moment of update
    if (!dataCollection.draftId && !draftIsSaved) return createDraft();
    if (!dataCollection.draftId) return;

    DBRequest({
      config,
      path: `drafts/${dataCollection.draftId}`,
      method: API_PATCH({ dataCollection }),
      onLoading: (value) => setDraftIsSaved(!value),
      onError: createDraft,
    });
  }

  // badge count
  const [badgeCount, setBadgeCount] = useState(null);

  return (
    draftType && (
      <Box className={className} sx={sx}>
        <DraftHistory
          titleFunction={titleFunction}
          onSelect={(data) => {
            setDataCollection(data);
            setOpenSelector(false);
          }}
          draftType={draftType}
          open={openSelector}
          onClose={() => setOpenSelector(false)}
          setBadgeCount={setBadgeCount}
        />
        <DraftIndicator
          draftIsSaved={draftIsSaved}
          onClick={() => setOpenSelector(true)}
          badgeCount={badgeCount}
        />
      </Box>
    )
  );
};

export default ExoDraftManager;
