import { Box, Dialog } from "@mui/material";
import NewUser from "../../components/new/NewUser";
import { useEffect, useState } from "react";
import Header from "../../components/special/Header";
import EmployeeList from "./EmployeeList";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import EmployeeInfo from "./EmployeeInfo";
import { useTranslation } from "react-i18next";
import { updateOrCreate } from "../../components/special/updateOrCreate";
import ExoDialog from "../../components/exo/ExoDialog";
import useFetch from "../../api/useFetch";

const Employees = () => {
  const { t } = useTranslation();
  const config = useConfig();

  const { apiData, setApiData, isLoading } = useFetch("users");
  useEffect(() => {
    if (apiData && apiData.data.length)
      handleEmployeeSelect(apiData.data[0].id);
  }, [apiData]);

  // new user
  const [openNewEmployee, setOpenNewEmployee] = useState(false);
  function handleCreatedUser(newUser) {
    console.log(newUser);

    const newEmployees = updateOrCreate(apiData, newUser);
    setApiData(newEmployees);
    handleEmployeeSelect(newUser.id);
  }

  // employee info
  const [employeeInfo, setEmployeeInfo] = useState();
  const [employeeInfoIsLoading, setEmployeeInfoIsLoading] = useState(true);
  function handleEmployeeSelect(id) {
    setOpenUserList(false);
    DBRequest({
      config,
      path: `users/${id}`,
      method: API_GET,
      onResponse: setEmployeeInfo,
      onLoading: setEmployeeInfoIsLoading,
    });
  }

  // mobile user List
  const [openUserList, setOpenUserList] = useState(false);
  return (
    <>
      <Header title={t("Employees")} />
      <Box className="flex w-full gap-4 flex-col-reverse lg:flex-row h-full overflow-hidden relative">
        <EmployeeList
          onSelect={handleEmployeeSelect}
          employees={apiData}
          isLoading={isLoading}
          setOpenNewEmployee={setOpenNewEmployee}
          selected={employeeInfo && employeeInfo.data}
          className="hidden lg:flex"
        />
        <EmployeeInfo
          employeeInfo={employeeInfo && employeeInfo.data}
          isLoading={employeeInfoIsLoading}
          onChange={handleCreatedUser}
          onOpen={() => setOpenUserList(true)}
        />
        <ExoDialog open={openUserList} onClose={() => setOpenUserList(false)}>
          <EmployeeList
            onSelect={handleEmployeeSelect}
            employees={apiData}
            isLoading={isLoading}
            setOpenNewEmployee={setOpenNewEmployee}
            selected={employeeInfo && employeeInfo.data}
          />
        </ExoDialog>
        <ExoDialog open={openNewEmployee} limitWidth>
          <NewUser
            onClose={() => setOpenNewEmployee(false)}
            onCreate={handleCreatedUser}
          />
        </ExoDialog>
      </Box>
    </>
  );
};

export default Employees;
