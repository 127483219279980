import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { leadingZero } from "../../../scenes/time-tracking/worktime";

const WorkDayHour = ({
  res = 4,
  resHeight = 12,
  hour = 1,
  hourEnd,
  onMouseDown,
  onTouchStart,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  var numOfElements = res;
  if (hourEnd - 1 === hour) numOfElements = 6;

  return (
    <Box
      className="block w-full"
      sx={{
        borderLeft: `1px solid ${colors.grey[500]}30`,
      }}
    >
      {[...Array(numOfElements)].map((e, i) => (
        <Box
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          key={i}
          sx={{
            borderBottom: `${i === 0 ? 1 : 0}px solid ${
              i === 0 ? colors.grey[500] : colors.grey[500] + "30"
            }`,
            height: `${resHeight}px`,
            marginLeft: `-${i === 0 ? 7 : 0}px`,
          }}
        ></Box>
      ))}
    </Box>
  );
};

export default WorkDayHour;
