import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import ProjectClientCard from "./project-info-cards/ProjectClientCard";
import ExoTabs from "../../components/exo/ExoTabs";
import FinanzialTab from "./project-info-tabs/FinancialTab";
import TeamTab from "./project-info-tabs/TeamTab";
import ActionsTab from "./project-info-tabs/ActionsTab";
import TimesTab from "./project-info-tabs/TimesTab";
import { useTranslation } from "react-i18next";
import OrdersTab from "./project-info-tabs/OrdersTab";
import InvoicesTab from "./project-info-tabs/InvoicesTab";
import InfoCard from "../../components/cards/InfoCard";
import DateLabel from "../../components/label/DateLabel";
import { hasPermissions } from "../../auth/hasPermissions";
import { ProjectStatus } from "../../components/special/Status";
import { ExpensesTab } from "./project-info-tabs/ExpensesTab";
import PictureNameCard from "../../components/cards/PictureNameCards/PictureNameCard";

const ProjectInfo = ({ selectedProject, onDelete, onChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  function updateProject(data) {
    onChange({
      ...selectedProject,
      data: { ...selectedProject.data, ...data },
    });
  }

  return (
    <Box
      className="lg:h-full w-full rounded-b-lg lg:rounded-lg backdrop-blur-lg overflow-y-auto p-2"
      sx={{
        backgroundColor: colors.glass,
      }}
    >
      {selectedProject && (
        <>
          <ProjectHead project={selectedProject.data} />
          <ProjectBody
            project={selectedProject.data}
            updateProject={updateProject}
            onDelete={onDelete}
          />
        </>
      )}
    </Box>
  );
};

const ProjectHead = ({ project }) => {
  const { t } = useTranslation();
  const infos = [
    {
      label: t("Start Date"),
      value: <DateLabel date={project.startDate} />,
    },
    {
      label: t("Deadline"),
      value: <DateLabel date={project.deadline} />,
    },
    {
      label: t("Status"),
      value: <ProjectStatus number={project.status} />,
    },
  ];
  return (
    <Box className="flex flex-col gap-2">
      <Box className="grid grid-cols-2 sm:grid-cols-3 gap-2">
        <ProjectClientCard project={project} />
        <InfoCard infos={infos} />

        <PictureNameCard
          data={project.supervisor}
          type="user"
          name={`${project.supervisor.firstName} ${project.supervisor.lastName}`}
          url={project.supervisor.url}
          phone={project.supervisor.phone}
          mail={project.supervisor.mail}
        />
      </Box>
    </Box>
  );
};

const ProjectBody = ({ project, updateProject, onDelete }) => {
  const { t } = useTranslation();
  function handleTeamChange(newMembers) {
    updateProject({ teamMembers: newMembers });
  }
  function handleStatusChange(newStatus) {
    updateProject({ status: newStatus });
  }

  const tabs = [
    {
      label: t("Financial"),
      content: <FinanzialTab project={project} />,
      hidden: !hasPermissions("projects-finance"),
    },
    {
      label: t("Expenses"),
      content: <ExpensesTab project={project} />,
      hidden: !hasPermissions("projects-finance"),
    },
    {
      label: t("Times"),
      content: <TimesTab project={project} />,
      hidden: !hasPermissions("projects-times"),
    },

    {
      label: t("Orders"),
      content: <OrdersTab project={project} />,
      hidden: !hasPermissions("projects-orders"),
    },
    {
      label: t("Invoices"),
      content: <InvoicesTab project={project} />,
      hidden: !hasPermissions("projects-invoices"),
    },
    {
      label: t("Actions"),
      content: (
        <ActionsTab
          project={project}
          onDelete={onDelete}
          onStatusChange={handleStatusChange}
        />
      ),
      hidden: !hasPermissions("projects-actions"),
    },
  ];
  return (
    <ExoTabs
      noBackground
      tabs={tabs}
      allowOverflow
      sx={{
        paddingTop: "20px",
      }}
      className="w-full h-full flex flex-col rounded-lg overflow-hidden"
    />
  );
};

export default ProjectInfo;
