import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import { DBRequest, useConfig } from "../../api/api";
import { ProjectGridSkeletons } from "./project-grid/ProjectGridSkeletons";
import CardGrid from "../../components/cards/layout/CardGrid";
import { ProjectGridItem } from "./project-grid/ProjectGridItem";
import Pagination from "../../components/special/Pagination";
import { ProjectAddElementButton } from "../../components/exo/project/ProjectAddElementButton";
import NewEmptyProject from "../../components/new/NewEmptyProject";
import ExoDialog from "../../components/exo/ExoDialog";
import { updateOrCreate } from "../../components/special/updateOrCreate";
import { hasPermissions } from "../../auth/hasPermissions";

export const ProjectsGrid = ({ projectStatus }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();
  const [apiData, setApiData] = useState(null);

  const typeMap = {
    own: "status[eq]=0",
    active: "status[eq]=0",
    archive: "status[ne]=0",
  };

  useEffect(() => {
    DBRequest({
      config,
      path:
        projectStatus === "own"
          ? `projects/own?${typeMap[projectStatus]}`
          : `projects?${typeMap[projectStatus]}`,
      onResponse: setApiData,
    });
  }, [projectStatus, config]);

  const [open, setOpen] = useState(false);

  function handleResponse(data) {
    setApiData(updateOrCreate(apiData, data));
  }

  return (
    <Box className="h-full flex flex-col overflow-hidden gap-2 justify-between">
      <Box className="flex flex-col gap-2 h-full overflow-auto">
        <CardGrid className="overflow-y-auto pb-4">
          {!apiData ? (
            <ProjectGridSkeletons n={5} />
          ) : (
            <>
              {hasPermissions("project", "write") && (
                <ProjectAddElementButton onClick={() => setOpen(true)} />
              )}
              {apiData.data.map((item) => (
                <ProjectGridItem key={item.id} project={item} />
              ))}
            </>
          )}
        </CardGrid>
      </Box>
      <Box sx={{ bgcolor: colors.glass }} className="rounded-lg">
        {apiData && (
          <Pagination
            data={apiData}
            setData={setApiData}
            filter={{ name: "name", filter: typeMap[projectStatus] || "" }}
            setLoading={() => {}}
          />
        )}
      </Box>
      <ExoDialog open={open} onClose={() => setOpen(false)}>
        <NewEmptyProject
          onClose={() => setOpen(false)}
          onCreate={(data) => {
            setOpen(false);
            handleResponse(data);
          }}
        />
      </ExoDialog>
    </Box>
  );
};
