import { Box, Skeleton, Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { DBRequest, useConfig } from '../../api/api';
import DBSearch from '../special/DBSearch';
import CompanyRadio from '../radio/CompanyRadio';
import NewCompany from '../new/NewCompany';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import ExoProcessing from '../exo/ExoProcessing';

const SelectCompany = ({ onSelect, preSelected = {} }) => {
  const { t } = useTranslation();
  const config = useConfig();

  // request
  const [searchResults, setSearchResults] = useState(null);

  // aktions
  const [selectedId, setSelectedId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  function handleSearchResultSelect(company) {
    DBRequest({
      config,
      path: `companies/${company.id}`,
      onResponse: (res) => {
        setSelectedId(res.data.id);
        onSelect(res.data);
      },
      onLoading: setIsProcessing,
    });
  }

  useEffect(() => {
    if (preSelected) setSelectedId(preSelected.id || null);
  }, [preSelected]);

  // new company
  const [openNewCompany, setOpenNewCompany] = useState(false);
  function handleNewCompany(data) {
    var newCompanies = searchResults;

    const newHit = {
      id: data.data.id,
      label: data.data.name,
      image: data.data.logo ? data.data.logo.temporaryUrl : '',
    };
    newCompanies.unshift(newHit);
    setSearchResults(newCompanies);
  }

  return (
    <DBSearch param={'companies'} onSearch={setSearchResults}>
      {!searchResults ? (
        <>
          {[...Array(8)].map((element, index) => (
            <Skeleton key={'skeleton-select-company-' + index} height={64} />
          ))}
        </>
      ) : (
        <>
          <CompanyRadio
            company={{
              logo: {},
              icon: <AddIcon />,
              id: 0,
              name: t('New Company'),
            }}
            radioKey="companyRadio"
            key={'select-company-' + 0}
            onClick={() => {
              setOpenNewCompany(true);
            }}
          />
          {searchResults.map((element) => (
            <CompanyRadio
              logo={element.image}
              company={{ name: element.label }}
              address={element.address}
              key={'select-company-' + element.id}
              onClick={(e) => handleSearchResultSelect(element)}
              checked={selectedId === element.id ? true : false}
            />
          ))}

          <Dialog
            onClose={() => setOpenNewCompany(false)}
            open={openNewCompany}
          >
            <NewCompany
              onClose={() => setOpenNewCompany(false)}
              onCreate={handleNewCompany}
            />
          </Dialog>
        </>
      )}
      <ExoProcessing open={isProcessing} label={t('Loading Company')} />
    </DBSearch>
  );
};

export default SelectCompany;
