import { useTranslation } from "react-i18next";
import Header from "../../components/special/Header";
import ExoTable from "../../components/exo/ExoTable";
import useFetch from "../../api/useFetch";
import ExoTableRecipient from "../../components/exo/ExoTableRecipient";
import { Box } from "@mui/material";
import { LetterStatus } from "../../components/special/Status";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";
import { useState } from "react";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ExoDialog from "../../components/exo/ExoDialog";
import { NewLetter } from "../../components/new/NewLetter";
import ExtendLetter from "./ExtendLetter";
import { useNavigate } from "react-router-dom";
import { recipientTypeMap } from "../../components/routing/routingMaps";

export const Letters = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { apiData, setApiData } = useFetch("letters");
  const [openNewLetter, setOpenNewLetter] = useState(false);
  const [edit, setEdit] = useState(null);

  const columns = [
    {
      header: t("Nr"), // header of column
      key: "id", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.id}</Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column

      cell: ({ row }) => <LetterStatus number={row.status}></LetterStatus>, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Subject"),
      key: "subject",
      width: "100%",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      onClick: (row) => navigate(`/letters/${row.id}`),
    },
    {
      header: t("Recipient"),
      key: "recipient",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      className: "lg:min-w-[300px]",
      cell: ({ row }) => <ExoTableRecipient row={row} />, // custom xml for cells in the column. you get the row as object
      onClick: (row) =>
        navigate(`/${recipientTypeMap[row.recipientType]}/${row.recipient.id}`),
    },
    {
      header: t("Attachments"),
      key: "attachmentCount",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      cell: ({ row }) => <Box className="monospace">{row.attachmentCount}</Box>,
    },
  ];

  const filters = {
    filter: {
      label: t("Filter"),
      items: [
        {
          type: "dropdown",
          label: t("Status"),
          key: "state2",
          baseValue: "status[eq]=",
          active: false,
          options: [
            {
              label: t("Created"),
              value: 0,
            },
            {
              label: t("Sent"),
              value: 1,
            },
            {
              label: t("Declined"),
              value: 2,
            },
          ],
        },
      ],
    },
  };

  return (
    <>
      <Header title={t("Letters")} />

      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New Letter")}
          icon={<NoteAddIcon />}
          onClick={() => setOpenNewLetter(true)}
        />
      </ExoTableToolbar>

      <ExoTable
        data={apiData}
        isLoading={!Boolean(apiData)}
        columns={columns}
        extendElement={({ row, onUpdate, onDelete }) => (
          <ExtendLetter
            row={row}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onEdit={setEdit}
          />
        )}
        filters={filters}
      />

      <ExoDialog open={openNewLetter || Boolean(edit)}>
        <NewLetter
          letters={apiData}
          setLetters={setApiData}
          startData={edit}
          onClose={() => {
            setOpenNewLetter(false);
            setEdit(null);
          }}
        />
      </ExoDialog>
    </>
  );
};
