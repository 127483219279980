import { Box, Skeleton, useTheme } from "@mui/material";
import EmployeeCard from "./EmployeeCard";
import { tokens } from "../../global/theme/tokens";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

const EmployeeList = ({
  onSelect = () => {},
  employees,
  isLoading,
  setOpenNewEmployee,
  selected,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      className={"flex flex-col gap-2 overflow-y-auto h-full pr-2 " + className}
      sx={{ minWidth: "400px", maxWidth: "100%" }}
    >
      <EmployeeCard
        employee={{
          firstName: t("New"),
          lastName: "",
          position: t("Add new Employee"),
          icon: <AddIcon />,
        }}
        onSelect={() => setOpenNewEmployee(true)}
      />
      {isLoading ? (
        <>
          {[...Array(30)].map((e, index) => (
            <EmployeeCardSkeleton key={index} />
          ))}
        </>
      ) : (
        <>
          {employees.data.map((employee) => (
            <EmployeeCard
              key={employee.id}
              employee={employee}
              onSelect={onSelect}
              selected={selected && selected.id === employee.id}
            />
          ))}
        </>
      )}
    </Box>
  );
};

const EmployeeCardSkeleton = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box
      className="flex w-full p-2 rounded-lg flex-row gap-3 hover:translate-x-2 transition-transform cursor-pointer backdrop-blur-lg"
      sx={{ backgroundColor: colors.glass }}
    >
      <Skeleton width="60px" height="60px" variant="circular" />
      <Box className="flex justify-start items-center">
        <Box>
          <Skeleton width="200px" height="24px" />
          <Skeleton width="150px" height="21px" />
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeList;
