import { Box, Dialog, Typography } from '@mui/material';
import { DBUploadData, useConfig } from '../../../api/api';
import useFetch from '../../../api/useFetch';
import ExoTable from '../../../components/exo/ExoTable';
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from '../../../components/exo/ExoTableToolbar';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import DateLabel from '../../../components/label/DateLabel';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExpandAccountStatement } from './ExpandAccountStatement';
import ExoForm from '../../../components/exo/ExoForm';
import * as Yup from 'yup';
import SingleFileUpload from '../../../components/upload/SingleFileUpload';
import Feedback from '../../../components/special/Feedback';
import { BankAccountListItem } from '../../../components/form/form-fields/TypeBankAccount';

const AccountStatementTab = () => {
  const config = useConfig();
  const { t } = useTranslation();
  const tableRef = useRef(null);

  const navigate = useNavigate();

  const { apiData, setApiData, isLoading } = useFetch(
    'account-statements?sortBy=startDate'
  );

  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: 'info',
    alertText: 'test',
  });

  function handleFileChange(newFile) {
    setFile(newFile);
  }

  function handleNewElement(newElement) {
    if (tableRef.current) tableRef.current.addRow(newElement.data);

    setOpen(false);
  }

  function handleFeedback(response) {
    if (!response.response) return;
    const type = 'error';
    const text = response.response.data.message;

    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  function addElement(data) {
    data = { ...data, file: file };

    const formData = new FormData();

    if (file && !file.id) {
      if (Object.keys(file).length) {
        formData.append(`file`, file);
      } else {
        formData.append(`file`, null);
      }
    }
    if (data.bankAccount)
      formData.append(`bankAccount`, JSON.stringify(data.bankAccount));
    if (data.startDate) formData.append(`startDate`, data.startDate);
    if (data.endDate) formData.append(`endDate`, data.endDate);

    const path = `account-statements`;

    DBUploadData({
      config,
      path: path,
      formData: formData,
      onResponse: handleNewElement,
      onFeedback: handleFeedback,
    });
  }

  const Columns = [
    {
      header: t('IBAN'), // header of column
      key: 'iban', // key of column in row-object
      thAlign: 'left', // header text alignment
      tdAlign: 'left', // cell text alignment
      width: '100%',
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <BankAccountListItem account={row.bankAccount} noCard />
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t('Start Date'), // header of column
      key: 'startDate', // key of column in row-object
      thAlign: 'left', // header text alignment
      tdAlign: 'left', // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace">
          <DateLabel date={row.startDate} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t('End Date'), // header of column
      key: 'endDate', // key of column in row-object
      thAlign: 'left', // header text alignment
      tdAlign: 'left', // cell text alignment
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      cell: ({ row }) => (
        <Box className="monospace">
          <DateLabel date={row.endDate} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
  ];

  const fields = [
    {
      label: t('Bank Account'),
      type: 'bankAccount',
      key: 'bankAccount',
    },
    {
      label: t('Start Date'),
      type: 'date',
      key: 'startDate',
    },
    {
      label: t('End Date'),
      type: 'date',
      key: 'endDate',
    },
  ];

  const validationSchema = Yup.object().shape({
    bankAccount: Yup.object().shape({
      iban: Yup.string().required(t('Is required')),
    }),
    startDate: Yup.date().required(t('Is required')),
    endDate: Yup.date().required(t('Is required')),
  });

  return (
    <Box className="w-full flex flex-col gap-0 h-full">
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t('Add Account Statement')}
          icon={<AddIcon />}
          onClick={() => setOpen(true)}
        />
      </ExoTableToolbar>
      <ExoTable
        ref={tableRef}
        extendElement={({ row, onDelete, onCreate }) => (
          <ExpandAccountStatement
            row={row}
            onDelete={onDelete}
            onCreate={onCreate}
          />
        )}
        data={apiData}
        isLoading={isLoading}
        columns={Columns}
        noBackground
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box
          className="p-4 flex flex-col lg:flex-row gap-4 items-center"
          sx={{ height: '90vh' }}
        >
          <Box
            sx={{
              minHeight: '300px',
            }}
            className="flex h-full w-full max-w-full"
          >
            <SingleFileUpload setFile={handleFileChange} />
          </Box>
          <Box className=" w-full flex h-full">
            <ExoForm
              header={t('Upload Account Statement')}
              fields={fields}
              validationSchema={validationSchema}
              onCancle={() => setOpen(false)}
              onSubmit={addElement}
              className="h-full flex flex-col gap-4 w-full"
            />
          </Box>
        </Box>
        <Feedback state={alertState} setState={setAlertState} />
      </Dialog>
    </Box>
  );
};

export default AccountStatementTab;
