import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useEffect, useState } from "react";
import Header from "../../components/special/Header";
import ExoTable from "../../components/exo/ExoTable";
import { MailStatus } from "../../components/special/Status";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { useTranslation } from "react-i18next";
import DateTimeLabel from "../../components/label/DateTimeLabel";
import { ExtendMail } from "./ExtendMail";
import { useNavigate } from "react-router-dom";
import ExoTableRecipient from "../../components/exo/ExoTableRecipient";
import { recipientTypeMap } from "../../components/routing/routingMaps";

const ServerMails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const navigate = useNavigate();

  // table
  const Columns = [
    {
      header: t("Date"),
      key: "updatedAt",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "130px",
      sortable: true, // enable sort function for column
      cell: ({ row }) => <DateTimeLabel dateTime={row.updatedAt} />,
      hiddenMobile: true,
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <MailStatus number={row.status} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Subject"),
      key: "subject",
      width: "100%",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      onClick: (row) => navigate(`/servermails/${row.id}`),
    },
    {
      header: t("Receiver"),
      key: "receiver",
      width: "230px",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => {
        if (row.source && row.source.recipient) {
          return <ExoTableRecipient row={row.source} />;
        } else {
          return (
            <Box className="flex flex-col gap-2">
              {row.receiver} <CC ccs={row.cc} />
            </Box>
          );
        }
      }, // custom xml for cells in the column. you get the row as object
      hiddenMobile: true,

      onClick: (row) => {
        if (row.source && row.source.recipient)
          navigate(
            `/${recipientTypeMap[row.source.recipientType]}/${
              row.source.recipient.id
            }`
          );
      },
    },
    {
      header: t("Attachments"),
      key: "attachmentCount",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <Box className="monospace">{row.attachmentCount}</Box>,
      hiddenMobile: true,
    },
  ];

  const filters = {
    filter: {
      label: t("Filter"),
      items: [
        {
          type: "dropdown",
          label: t("Status"),
          key: "state2",
          baseValue: "status[eq]=",
          active: false,
          options: [
            {
              label: t("Queued"),
              value: 0,
            },
            {
              label: t("Sent"),
              value: 1,
            },
            {
              label: t("Failed"),
              value: 2,
            },
          ],
        },
        {
          type: "dropdown",
          label: t("Type"),
          key: "state3",
          baseValue: "sourceType[eq]=",
          active: false,
          options: [
            {
              label: t("Contract"),
              value: "contract",
            },
            {
              label: t("Invoice"),
              value: "invoice",
            },
            {
              label: t("Offer"),
              value: "offer",
            },
            {
              label: t("Payment Reminder"),
              value: "payment_reminder",
            },
            {
              label: t("Order"),
              value: "order",
            },
            {
              label: t("Letter"),
              value: "letter",
            },
          ],
        },
      ],
    },
  };

  const [mails, setMails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "maillogs",
      method: API_GET,
      onResponse: setMails,
      onLoading: setIsLoading,
    });
  }, [config]);

  return (
    <>
      <Header title={t("Sent Mails")} />
      <ExoTable
        columns={Columns}
        data={mails}
        isLoading={isLoading}
        extendElement={({ row, onUpdate, onDelete }) => (
          <ExtendMail row={row} onUpdate={onUpdate} onDelete={onDelete} />
        )}
        filters={filters}
      />
    </>
  );
};
const CC = ({ ccs }) => {
  return (
    <Box>
      {ccs.map((cc) => (
        <Typography variant="h6">{cc.name}</Typography>
      ))}
    </Box>
  );
};
export default ServerMails;
