import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import FormFields from '../form/FormFields';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const NewAddress = ({
  dataCollection = {},
  updateDataCollection,
  updateValidation,
  fieldKey = 'address',
}) => {
  const { t } = useTranslation();

  const [addressData, setAddressData] = useState(dataCollection[fieldKey]);

  const addressFields = [
    {
      label: t('Street'),
      type: 'text',
      key: 'street',
      required: true,
    },
    {
      label: t('House Number'),
      type: 'text',
      key: 'houseNumber',
      required: true,
    },
    {
      label: t('ZIP Code'),
      type: 'text',
      key: 'zipCode',
      required: true,
    },
    {
      label: t('City'),
      type: 'text',
      key: 'city',
      required: true,
    },
    {
      label: t('Country'),
      type: 'country',
      key: 'country',
      required: true,
    },
    {
      label: `${t('Addition')} (${t('optional')})`,
      type: 'text',
      key: 'addressAddition',
      required: false,
    },
  ];

  function handleUpdateAddressData(data) {
    setAddressData({ ...addressData, ...data });
    updateDataCollection({ ...addressData, ...data });
    checkValidation({ ...addressData, ...data });
  }

  async function checkValidation(address) {
    try {
      await validationSchema.validate(address, {
        abortEarly: false,
      });
      updateValidation(true);
    } catch (err) {
      updateValidation(false);
    }
  }

  const validationSchema = Yup.object().shape({
    street: Yup.string().required(t('Street is required')),
    houseNumber: Yup.string().required(t('House Number is required')),
    zipCode: Yup.string().required(t('Zip Code is required')),
    city: Yup.string().required(t('City is required')),
    country: Yup.string().required(t('Country is required')),
  });

  return (
    <Box className="flex flex-col gap-4">
      <FormFields
        fields={addressFields}
        dataCollection={addressData || {}}
        updateDataCollection={handleUpdateAddressData}
        validationSchema={validationSchema}
      />
    </Box>
  );
};

export default NewAddress;
