import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";

const Header = ({ title, subtitle, className, sx }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box className={`px-2 md:px-0 pt-0 pb-2 ${className}`}>
      <Typography variant="h2" className="flex items-center gap-4">
        {title}
      </Typography>
      <Typography
        variant="h5"
        color={colors.greenAccent[500]}
        className="hidden"
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
