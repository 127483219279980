import { Box, Typography } from "@mui/material";
import CardContainer from "../layout/CardContainer";

const InfoGridCard = ({ infos, span }) => {
  return (
    <CardContainer span={span}>
      <table className="w-full">
        <tbody>
          {infos.map((info, index) => (
            <tr key={index}>
              <td className="pr-2">
                <Typography
                  className="flex justify-center"
                  key={index + "label"}
                  value={info.value}
                  sx={{ fontWeight: 500 }}
                >
                  {info.label}
                </Typography>
              </td>
              <td>
                <Typography
                  className="col-span-2"
                  key={index + "value"}
                  value={info.value}
                >
                  {info.value}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </CardContainer>
  );
};

export default InfoGridCard;
