import { Box, Table, Typography, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { API_DELETE, DBRequest, useConfig } from "../../api/api";
import { useTranslation } from "react-i18next";
import { tokens } from "../../global/theme/tokens";
import useFetch from "../../api/useFetch";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useState } from "react";
import CardGrid from "../../components/cards/layout/CardGrid";
import SpeedDialMenu from "../../components/menu/SpeedDialMenu";
import InfoCard from "../../components/cards/InfoCard";
import MoneyCard from "../../components/cards/MoneyCard";
import { MultiContactCard } from "../../components/cards/MultiContactCard";
import AddressCard from "../../components/cards/AddressCard";
import ExoDialog from "../../components/exo/ExoDialog";
import NewCompany from "../../components/new/NewCompany";
import CardContainer from "../../components/cards/layout/CardContainer";
import { CardContentText } from "../../components/cards/content/CardContentText";
import { CardContentLink } from "../../components/cards/content/CardContentLink";
import SkeletonCard from "../../components/cards/SkeletonCard";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PictureNameCard from "../../components/cards/PictureNameCards/PictureNameCard";
import TableCard from "../../components/cards/TableCard";

export const ShowCompany = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const { apiData, setApiData } = useFetch(`companies/${id}`);

  // menu
  function handleDelete() {
    DBRequest({
      config,
      path: `companies/${apiData.data.id}`,
      method: API_DELETE,
      onResponse: navigate(`/companies`),
    });
  }

  const actions = [
    {
      icon: <ModeEditIcon />,
      name: t("Edit"),
      onClick: handleEdit,
    },
    { icon: <DeleteOutlineIcon />, name: t("Delete"), onClick: handleDelete },
  ];

  //edit company
  const [open, setOpen] = useState(false);
  const [startData, setStartData] = useState(null);
  function handleEdit() {
    setStartData(apiData.data);
    setOpen(true);
  }

  function handleUpdate(data) {
    setApiData(data);
    //onUpdate(data.data);
  }

  const infos = [
    {
      label: t("Nr"),
      value: apiData?.data.nr,
    },
  ];

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      <CardGrid>
        {!apiData ? (
          <CompanySkeleton />
        ) : (
          <>
            <SpeedDialMenu actions={actions} />
            <PictureNameCard
              data={apiData.data}
              span={1}
              pictureUrl={apiData.data.logo && apiData.data.logo.thumbnailUrl}
              name={apiData.data.name}
              type="company"
            />
            <InfoCard infos={infos} />
            <MoneyCard title={t("Sales")} amount={apiData.data.salesTotal} />
            <MultiContactCard
              label={t("Phonenumbers")}
              contactArray={apiData.data.telephoneNumbers}
              nameKey="phoneType"
              valueKey="phoneNumber"
              Icon={PhoneIcon}
              linkPrefix="tel:"
            />
            <MultiContactCard
              label={t("E-Mailaddresses")}
              span={2}
              contactArray={apiData.data.emails}
              nameKey="emailType"
              valueKey="emailAddress"
              Icon={EmailIcon}
              linkPrefix="mailto:"
            />
            <AddressCard address={apiData.data.address} />
            <TableCard span={5} id={id} type={"company"} />
          </>
        )}
        <ExoDialog open={open} limitWidth>
          <NewCompany
            fullWidth
            onClose={() => setOpen(false)}
            onUpdate={handleUpdate}
            startData={startData}
          />
        </ExoDialog>
      </CardGrid>
    </Box>
  );
};

const CompanySkeleton = () => {
  return (
    <>
      <SkeletonCard height={186} />
      <SkeletonCard height={186} span={2} />
      <SkeletonCard height={186} />
      <SkeletonCard height={104} />
    </>
  );
};
