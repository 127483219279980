import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../global/theme/tokens";
import CardGrid from "../../../components/cards/layout/CardGrid";
import SkeletonCard from "../../../components/cards/SkeletonCard";
import { useEffect, useState } from "react";
import { API_DELETE, API_GET, DBRequest, useConfig } from "../../../api/api";
import TextCard from "../../../components/cards/TextCard";
import SpeedDialMenu from "../../../components/menu/SpeedDialMenu";
import PopUpContainer from "../../../components/popup/PopUpContainer";
import Share from "../../../components/special/Share";
import Feedback from "../../../components/special/Feedback";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExoCostCenter from "../../../components/exo/ExoCostCenter";
import InfoCard from "../../../components/cards/InfoCard";
import MoneyCard from "../../../components/cards/MoneyCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentCard from "../../../components/cards/PercentCard";
import FileCard from "../../../components/cards/FilesCard";
import DownloadIcon from "@mui/icons-material/Download";
import FilePreviewCard from "../../../components/cards/FilePreviewCard";
import CardContainer from "../../../components/cards/layout/CardContainer";
import { ReceiptPosition } from "../../../components/form/form-fields/TypeReceiptPositions";
import MoneyLabel from "../../../components/label/MoneyLabel";
import { element } from "prop-types";

const ExtendReceipt = ({ row, onUpdate, onClickEdit, onDelete, skr03 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const [receipt, setReceipt] = useState(null);

  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: `receipts/${row.id}`,
      method: API_GET,
      onResponse: handleResponse,
    });
  }, [config, row]);

  function handleResponse(data) {
    setReceipt(data.data);
  }

  // menu
  const actions = [
    {
      icon: <ModeEditIcon />,
      name: t("Edit"),
      onClick: () => onClickEdit(receipt),
    },
    {
      icon: <ContentCopyIcon />,
      name: t("Copy"),
      onClick: handleCopy,
    },
    {
      icon: <PrintIcon />,
      name: t("Print"),
      onClick: () => handlePrint(receipt && receipt.file.temporaryUrl),
      hidden: receipt && receipt.file ? false : true,
    },
    {
      icon: <ShareIcon />,
      name: t("Share"),
      onClick: () => setOpenShare(true),
    },
    { icon: <DeleteOutlineIcon />, name: t("Delete"), onClick: handleDelete },
  ];

  function handleCopy() {
    const dataCopied = {};
    dataCopied.title = receipt.title;
    onClickEdit(dataCopied);
  }

  // share
  const [openShare, setOpenShare] = useState(false);

  // print
  function handlePrint(pdfUrl) {
    // Open a new window or iframe with the PDF URL
    const printWindow = window.open(pdfUrl, "_blank");

    // Wait for the PDF to load
    printWindow.onload = () => {
      // Trigger the print dialog
      printWindow.print();
    };
  }

  // delete
  function handleDelete() {
    DBRequest({
      config,
      path: `receipts/${receipt.id}`,
      method: API_DELETE,
      onFeedback: handleAlertOpen,
      onResponse: (res) => onDelete(row),
    });
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  if (!receipt) return <ReceiptSkeleton />;

  let infos = [
    {
      label: t("Receipt Number"),
      value: receipt.receiptNr || "-",
      type: "number",
    },
    {
      label: t("Receipt Date"),
      value: receipt.receiptDate,
      type: "date",
    },
    {
      label: t("Due Date"),
      value: receipt.dueDate,
      type: "date",
    },
    {
      label: t("Origin"),
      value: t(receipt.origin),
    },
    {
      label: t("Reverse Charge"),
      value: Boolean(receipt.reverseCharge) ? t("Yes") : t("No"),
    },
  ];

  if (receipt.seller)
    infos = infos.concat({
      label: t("Seller"),
      value: receipt.seller.name,
    });

  const files = receipt.file
    ? [
        {
          label: t("Receipt"),
          url: receipt.file.temporaryUrl,
          icon: <DownloadIcon />,
        },
      ]
    : [];

  return (
    <CardGrid>
      <TextCard text={receipt.title} span={5} />
      <FilePreviewCard file={receipt.file} />
      <InfoCard infos={infos} />

      <MoneyCard
        amount={receipt.amount}
        Icon={AttachMoneyIcon}
        title={t("Costs")}
        subTitle={
          <Typography variant="h6">
            <b>
              <MoneyLabel money={receipt.openAmount} />
            </b>{" "}
            {t("open amount")}
          </Typography>
        }
      />

      <FileCard files={files} />
      {receipt.positions && (
        <CardContainer span={3} className="flex flex-col gap-4">
          {receipt.positions.map((element) => (
            <ReceiptPosition
              key={element.id}
              element={element}
              onClick={() => {}}
              noBackground
            />
          ))}
        </CardContainer>
      )}

      {/*receipt.seller && (
        <CardContainer>
          <Box className="w-full h-full flex justify-center items-center gap-2 flex-wrap">
            <Typography>{receipt.seller.datevId}</Typography>
            <Typography>{receipt.seller.name}</Typography>
          </Box>
        </CardContainer>
      )*/}

      <PopUpContainer open={openShare} onClose={() => setOpenShare(false)}>
        <Share
          title={`${t("Share Receipt")} ${receipt.id}`}
          url={receipt.file ? receipt.file.temporaryUrl : ""}
        />
      </PopUpContainer>
      <SpeedDialMenu actions={actions} />
      <Feedback setState={setAlertState} state={alertState} />
    </CardGrid>
  );
};
const ReceiptSkeleton = () => {
  return (
    <CardGrid>
      <SkeletonCard height={52} span={5} />
      <SkeletonCard height={191} />
      <SkeletonCard height={191} />
      <SkeletonCard height={191} />
      <SkeletonCard height={191} />
      <SkeletonCard height={156} span={4} />
    </CardGrid>
  );
};

export default ExtendReceipt;
