import { API_PATCH, API_POST, DBRequest, useConfig } from "../../api/api";
import ExoStepper, { FinalPage } from "../exo/ExoStepper";
import StepRecipient from "../steps/StepRecipient";
import StepDelivery from "../steps/StepDelivery";
import StepPositions from "../steps/StepPositions";
import StepCustomStartUp from "../steps/StepCustomStartUp";
import findDifferences from "../exo/findDifferencesInDataCollection";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import moment from "moment";
import { StepTemplate } from "../steps/StepTemplate";
import { PositionImage } from "../exo/positions/collapse-modules/PositionImage";
import { PositionDescription } from "../exo/positions/collapse-modules/PositionDescription";
import { PositionNumber } from "../exo/positions/collapse-modules/PositionNumber";
import { PositionText } from "../exo/positions/collapse-modules/PositionText";
import { PositionCheckbox } from "../exo/positions/collapse-modules/PositionCheckbox";
import { PositionProgressPaymentCalculation } from "../exo/positions/collapse-modules/PositionProgressPaymentCalcilation";
import { PositionPreCalculation } from "../exo/positions/collapse-modules/PositionPreCalculation";
import { PositionCopy } from "../exo/positions/collapse-modules/PositionCopy";

const NewCustomInvoice = ({
  invoices,
  setInvoices,
  onClose,
  startData,
  onUpdate,
  debug,
}) => {
  const { t } = useTranslation();
  const config = useConfig();
  const [steps, setSteps] = useState(generateSteps());

  useEffect(() => {
    setSteps(generateSteps());
  }, [startData]);

  function generateSteps() {
    var requiredFields = [
      {
        label: t("Title"),
        type: "text",
        key: "title",
      },
      {
        label: t("Invoice Date"),
        type: "date",
        key: "date",
      },
      {
        label: t("Delivery Date"),
        type: "date",
        key: "deliveryDate",
      },
      {
        label: t("Payment Deadline in Days"),
        type: "text",
        key: "paymentDeadlineInDays",
      },
      {
        label: `${t("Note")} (${t("optional")})`,
        type: "description",
        key: "note",
        multiline: true,
      },
    ];
    var requiredValidationSchema = Yup.object().shape({
      title: Yup.string().required(t("Title is required")),
      date: Yup.string().required(t("Date is required")),
      deliveryDate: Yup.string().required(t("Date is required")),
      paymentDeadlineInDays: Yup.number().required(
        t("Payment Deadline in Days is required")
      ),
    });
    if (config) {
      const useDeliveryDateRange = startData
        ? Boolean(startData.deliveryDateStart)
        : Boolean(parseInt(config.invoice.useDeliveryDateRange));

      requiredFields = [
        {
          label: t("Title"),
          type: "text",
          key: "title",
        },
        {
          label: `${t("Project")} (${t("optional")})`,
          type: "project",
          key: "project",
          apiUrl: "projects",
        },
        {
          label: t("Invoice Date"),
          type: "date",
          key: "date",
        },
        {
          label: useDeliveryDateRange
            ? t("Delivery Date Range")
            : t("Delivery Date"),
          type: useDeliveryDateRange ? "dateRange" : "date",
          key: useDeliveryDateRange ? "deliveryDate" : "deliveryDateEnd",
        },
        {
          label: t("Payment Deadline in Days"),
          type: "text",
          key: "paymentDeadlineInDays",
        },
        {
          label: `${t("Note")} (${t("optional")})`,
          type: "description",
          key: "note",
          multiline: true,
        },
      ];
      requiredValidationSchema = useDeliveryDateRange
        ? Yup.object().shape({
            title: Yup.string().required(t("Title is required")),
            date: Yup.string().required(t("Date is required")),
            deliveryDateStart: Yup.string().required(t("Date is required")),
            deliveryDateEnd: Yup.string().required(t("Date is required")),
            paymentDeadlineInDays: Yup.number().required(
              t("Payment Deadline in Days is required")
            ),
          })
        : Yup.object().shape({
            title: Yup.string().required(t("Title is required")),
            date: Yup.string().required(t("Date is required")),
            deliveryDateEnd: Yup.string().required(t("Date is required")),
            paymentDeadlineInDays: Yup.number().required(
              t("Payment Deadline in Days is required")
            ),
          });
    }

    const optionalFields = [
      {
        label: `${t("Discount")} (${t("optional")})`,
        type: "checkbox",
        key: "discount",
      },
      {
        label: `${t("Discount Rate")} (${t("optional")})`,
        type: "number",
        key: "discountRate",
        decimalPlaces: 2,
      },
      {
        label: `${t("Discount Days To Pay")} (${t("optional")})`,
        type: "number",
        key: "discountDaysToPay",
        decimalPlaces: 0,
      },
      {
        label: `${t("Images")} (${t("optional")})`,
        type: "images",
        key: "additionalFiles",
        maxItems: "15",
        maxSizeKB: "3000",
      },
    ];

    var collapsePositionModules = [
      { component: PositionImage, key: "image", label: "Image" },
      {
        component: PositionDescription,
        key: "description",
        label: "Description",
      },
      {
        component: PositionNumber,
        key: "taxRate",
        label: "Tax Rate",
        unit: "%",
        decimalPlaces: 0,
      },
      {
        component: PositionText,
        key: "unit",
        label: "Unit",
      },
      {
        component: PositionCopy,
        label: "Duplicate position",
      },
    ];

    if (startData && startData.sourceType === "order")
      collapsePositionModules = [
        ...collapsePositionModules,
        {
          component: PositionCheckbox,
          key: "isOrderPosition",
          label: "Settle from order",
        },
        {
          component: PositionProgressPaymentCalculation,
          key: "price",
          label: "Progress Payment Calculation",
        },
      ];

    collapsePositionModules = [
      ...collapsePositionModules,
      {
        component: PositionPreCalculation,
        key: "price",
        label: "Calculation of sales price",
      },
    ];

    return [
      {
        label: t("Recipient"),
        content: ({
          dataCollection,
          updateValidation,
          updateDataCollection,
        }) => (
          <StepRecipient
            updateDataCollection={updateDataCollection}
            dataCollection={dataCollection}
            updateValidation={updateValidation}
          />
        ),
      },
      {
        label: t("Template"),
        content: ({
          dataCollection,
          updateValidation,
          updateDataCollection,
        }) => (
          <StepTemplate
            updateDataCollection={updateDataCollection}
            dataCollection={dataCollection}
            updateValidation={updateValidation}
            templateType="invoice"
          />
        ),
      },
      {
        label: t("Info"),
        content: ({
          dataCollection,
          updateValidation,
          updateDataCollection,
        }) => (
          <StepCustomStartUp
            fields={requiredFields}
            updateDataCollection={updateDataCollection}
            dataCollection={dataCollection}
            updateValidation={updateValidation}
            validationSchema={requiredValidationSchema}
          />
        ),
      },
      {
        label: `(${t("Optional")})`,
        content: ({
          dataCollection,
          updateValidation,
          updateDataCollection,
        }) => (
          <StepCustomStartUp
            fields={optionalFields}
            updateDataCollection={updateDataCollection}
            dataCollection={dataCollection}
            updateValidation={updateValidation}
          />
        ),
      },

      {
        label: t("Custom Positions"),
        content: ({
          dataCollection,
          updateValidation,
          updateDataCollection,
        }) => (
          <StepPositions
            updateDataCollection={updateDataCollection}
            dataCollection={dataCollection}
            updateValidation={updateValidation}
            imageType={`INVOICE_ATTACHMENT`}
            imageApiFilter={
              startData && startData.id
                ? `orderImages[has]=${startData.id}`
                : `orderImages[doesnothave]=0`
            }
            collapseModules={collapsePositionModules}
          />
        ),
      },

      {
        label: t("Delivery"),
        content: ({
          dataCollection,
          updateValidation,
          updateDataCollection,
        }) => (
          <StepDelivery
            useInvoiceMail={true}
            updateDataCollection={updateDataCollection}
            dataCollection={dataCollection}
            updateValidation={updateValidation}
            defaultRecipientMailKey="isInvoiceMail"
            allowMailContent={true}
          />
        ),
      },
    ];
  }

  const finalStep = {
    operation: ({
      dataCollection,
      handleResponse,
      setIsLoading,
      handleError,
    }) => {
      const diff = findDifferences(startData || {}, dataCollection);

      DBRequest({
        config,
        path: dataCollection.id ? `invoices/${dataCollection.id}` : `invoices`,
        method: dataCollection.id
          ? API_PATCH({ ...diff, recipientType: dataCollection.recipientType })
          : API_POST(dataCollection),
        onResponse: handleRes,
        onLoading: setIsLoading,
        onError: handleError,
      });

      function handleRes(data) {
        handleResponse(data);
        onUpdate(data);
      }
    }, // your operation with the data
    content: (response) => (
      <FinalPage response={response} data={invoices} setData={setInvoices} />
    ), // component with final operations and the feedback
  };

  const [defaultData, setDefaultData] = useState({});

  useEffect(() => {
    if (!config) return;
    setSteps(generateSteps());
    const today = moment().format();

    const useDeliveryDateRange = Boolean(
      parseInt(config.invoice.useDeliveryDateRange)
    );
    const newDefaultData = {
      date: today,
      deliveryDateStart: useDeliveryDateRange ? today : null,
      deliveryDateEnd: today,
      paymentDeadlineInDays: config.invoice.paymentDeadlineInDays,
      discountRate: config.invoice.discountRate,
      discountDaysToPay: config.invoice.discountPaymentDeadlineInDays,
      useDeliveryDateRange,
      sourceType: "custom",
    };
    setDefaultData(newDefaultData);
  }, [config]);

  return (
    <ExoStepper
      debug={debug}
      draftType={"custom-invoice"}
      label={
        startData && startData.id ? t("Edit Invoice") : t("New Custom Invoice")
      }
      startDataCollection={
        startData && startData.id ? startData : { ...defaultData, ...startData }
      }
      message={t("Create Invoice")}
      onClose={onClose}
      data={steps}
      finalStep={finalStep}
      previewDocument="invoice"
    />
  );
};

export default NewCustomInvoice;
