import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import EmailIcon from "@mui/icons-material/Email";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

const StatusDot = ({ variant, onClick, statusColor, statusIcon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  var icon = <EmailIcon />;
  var color = colors.primary[600];
  switch (variant) {
    case "draft":
      icon = <ModeEditIcon />;
      color = colors.blueAccent[600];
      break;
    case "open":
      icon = <HourglassFullIcon />;
      color = colors.orangeAccent[600];
      break;
    case "sent":
      icon = <EmailIcon />;
      color = colors.blueAccent[600];
      break;
    case "accepted":
      icon = <CheckCircleIcon />;
      color = colors.greenAccent[600];
      break;
    case "rejected":
      icon = <CancelIcon />;
      color = colors.redAccent[600];
      break;
    case "expired":
      icon = <QueryBuilderIcon />;
      color = colors.redAccent[600];
      break;
    case "finished":
      icon = <FlagCircleIcon />;
      color = colors.grey[600];
      break;
    case "legal":
      icon = <GavelOutlinedIcon />;
      color = colors.redAccent[500];
      break;
    case "base":
      icon = <QuestionMarkIcon />;
      color = colors.grey[600];
      break;
  }

  return (
    <Box
      onClick={onClick}
      className="min-h-[30px] rounded-full flex justify-center items-center aspect-square"
      sx={{
        bgcolor: (statusColor || color) + "25",
        color: statusColor || color,
      }}
    >
      {statusIcon || icon}
    </Box>
  );
};

export default StatusDot;
