import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { API_DELETE, DBRequest, useConfig } from "../../api/api";
import { useState } from "react";
import CardGrid from "../../components/cards/layout/CardGrid";
import ExoResendMail from "../../components/exo/ExoResendMail";
import SpeedDialMenu from "../../components/menu/SpeedDialMenu";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import TextCard from "../../components/cards/TextCard";
import PictureNameCard from "../../components/cards/PictureNameCards/PictureNameCard";
import useFetch from "../../api/useFetch";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileCard from "../../components/cards/FilesCard";
import DownloadIcon from "@mui/icons-material/Download";
import { ExoAttachments } from "../../components/exo/ExoAttachments";
import CardContainer from "../../components/cards/layout/CardContainer";
import StyledTextContainer from "../../components/preview/StyledTextContainer";
import RenderHTML from "../../components/preview/RenderHTML";
import MailLogCard from "../../components/cards/mailLogCard/MailLogCard";

const ExtendLetter = ({ row, onUpdate, onDelete, onEdit }) => {
  const { t } = useTranslation();
  const config = useConfig();

  const { apiData } = useFetch(`letters/${row.id}`);
  // menu
  const actions = [
    {
      icon: <ModeEditIcon />,
      name: t("Edit"),
      onClick: () => handleEdit(apiData && apiData.data),
    },
    {
      icon: <ContentCopyIcon />,
      name: t("Copy"),
      onClick: handleCopy,
    },
    {
      icon: <ForwardToInboxIcon />,
      name: t("Send Again"),
      onClick: () => setOpenResendMail(true),
      hidden: apiData && apiData.data.file ? false : true,
    },
    {
      icon: <PrintIcon />,
      name: t("Print"),
      onClick: () => handlePrint(apiData && apiData.data.file.temporaryUrl),
      hidden: apiData && apiData.data.file ? false : true,
    },
    { icon: <DeleteOutlineIcon />, name: t("Delete"), onClick: handleDelete },
  ];
  //copy
  function handleCopy() {
    const dataCopied = {};
    dataCopied.subject = apiData.data.subject;
    dataCopied.content = apiData.data.content;

    onEdit(dataCopied);
  }

  // edit
  function handleEdit() {
    onEdit(apiData.data);
  }

  // print
  function handlePrint(pdfUrl) {
    // Open a new window or iframe with the PDF URL
    const printWindow = window.open(pdfUrl, "_blank");

    // Wait for the PDF to load
    printWindow.onload = () => {
      // Trigger the print dialog
      printWindow.print();
    };
  }

  // delete
  function handleDelete() {
    DBRequest({
      config,
      path: `letters/${row.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(row),
    });
  }

  //rensend mail
  const [openResendMail, setOpenResendMail] = useState(false);

  // FILES
  const files = [
    {
      label: t("Letter"),
      icon: <DownloadIcon />,
      url: apiData && apiData.data.file ? apiData.data.file.temporaryUrl : "",
    },
  ];
  return (
    <Box className="flex flex-col gap-4 relative overflow-y-auto">
      {!apiData ? (
        <LetterSkeleton />
      ) : (
        <>
          <ExoResendMail
            recipient={apiData.data.recipient}
            type="letters"
            id={apiData.data.id}
            open={openResendMail}
            onClose={() => setOpenResendMail(false)}
          />
          <SpeedDialMenu actions={actions} />
          <TextCard text={apiData.data.subject} />

          <CardGrid>
            <PictureNameCard
              data={apiData.data.recipient}
              title={t("Recipient")}
              type={apiData.data.recipientType}
              pictureUrl={
                apiData.data.recipient.logo &&
                apiData.data.recipient.logo.thumbnailUrl
              }
              name={
                apiData.data.recipient.firstName
                  ? `${apiData.data.recipient.firstName} ${apiData.data.recipient.lastName}`
                  : apiData.data.recipient.name
              }
              colorPool={apiData.data.colorPool}
            />
            <FileCard files={files} />
            <CardContainer>
              <ExoAttachments attachments={apiData.data.attachments} />
            </CardContainer>

            <PictureNameCard
              data={apiData.data.contactPerson}
              type="user"
              title={t("Contact Person")}
              pictureUrl={
                apiData.data.contactPerson &&
                apiData.data.contactPerson.thumbnailUrl
              }
              name={`${apiData.data.contactPerson.firstName} ${apiData.data.contactPerson.lastName}`}
            />

            <MailLogCard span={2} logs={apiData.data.mailLogs} />

            <CardContainer span={88}>
              <StyledTextContainer backgroundColor="transparent" fullWidth>
                <RenderHTML HTML={apiData.data.content} />
              </StyledTextContainer>
            </CardContainer>
          </CardGrid>
        </>
      )}
    </Box>
  );
};

const LetterSkeleton = () => {
  return <CardGrid></CardGrid>;
};

export default ExtendLetter;
