import { useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import ContactPictureNameCard from "./ContactPictureNameCard";
import CompanyPictureNameCard from "./CompanyPictureNameCard";
import UserPictureNameCard from "./UserPictureNameCard";

const PictureNameCard = ({
  data = null,
  title = null,
  pictureUrl = "",
  name,
  span = 1,
  avatarSize = "60px",
  mail = null,
  phone = null,
  url = null,
  type = "contact",
  position = null,
  color,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const typeMap = {
    contact: (
      <ContactPictureNameCard
        data={data}
        title={title}
        pictureUrl={pictureUrl}
        name={name}
        span={span}
        avatarSize={avatarSize}
        mail={mail}
        phone={phone}
        url={url}
        position={position}
        color={color}
      />
    ),
    company: (
      <CompanyPictureNameCard
        data={data}
        title={title}
        pictureUrl={pictureUrl}
        name={name}
        span={span}
        avatarSize={avatarSize}
        mail={mail}
        phone={phone}
        url={url}
        position={position}
        color={color}
      />
    ),
    user: (
      <UserPictureNameCard
        data={data}
        title={title}
        pictureUrl={pictureUrl}
        name={name}
        span={span}
        avatarSize={avatarSize}
        mail={mail}
        phone={phone}
        url={url}
        position={position}
        color={color}
      />
    ),
  };

  return typeMap[type];
};

export default PictureNameCard;
