import { useState } from 'react';
import LabeledBox from '../../special/LabeledBox';
import { Box, Button, Typography, useTheme } from '@mui/material';
import ExoDialog from '../../exo/ExoDialog';
import useFetch from '../../../api/useFetch';
import MoneyLabel from '../../label/MoneyLabel';
import { BankCard } from '../../../scenes/bank-accounts/BankCard';
import { tokens } from '../../../global/theme/tokens';
import TypeSkeleton from './TypeSkeleton';

export const TypeBankAccount = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
}) => {
  const [open, setOpen] = useState(false);

  const { apiData } = useFetch(`bankaccounts`);

  function handleSelect(account) {
    setOpen(false);
    updateValidationOnChange(field.key, account);
  }

  return (
    <>
      <LabeledBox label={field.label} onClick={() => setOpen(true)}>
        {dataCollection[field.key] && (
          <BankAccountListItem account={dataCollection[field.key]} />
        )}
      </LabeledBox>
      <ExoDialog
        open={open}
        onClose={() => setOpen(false)}
        className="gap-2 flex flex-col"
      >
        {apiData ? (
          apiData.data.map((account) => (
            <BankAccountListItem
              account={account}
              key={account.id}
              onSelect={handleSelect}
              selected={
                dataCollection[field.key] &&
                dataCollection[field.key].id === account.id
              }
            />
          ))
        ) : (
          <TypeSkeleton n={3} />
        )}
      </ExoDialog>
    </>
  );
};

export const BankAccountListItem = ({
  account,
  selected,
  onSelect,
  noCard,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const Container = onSelect ? Button : Box;

  function handleClick() {
    if (onSelect) onSelect(account);
  }
  console.log(account);

  if (!account) return;
  const { accountHolder, iban } = account;

  return (
    <Container
      onClick={handleClick}
      sx={{
        padding: '8px 15px',
        bgcolor: onSelect ? colors.card : 'transparent',
        border: selected ? `1px solid ${colors.info}` : `1px solid transparent`,
      }}
      className="rounded-lg py-1 md:py-[8px] px-1 md:px-[15px] "
    >
      <Box className="flex gap-4 w-full">
        {!noCard && (
          <Box className="w-[80px] md:w-[130px]">
            <BankCard bank={account} />
          </Box>
        )}
        <Box className="flex flex-col justify-start">
          <Typography fontSize={16} fontWeight={500} textAlign="left">
            {accountHolder}
          </Typography>
          <Typography variant="h6" fontSize={13} textAlign="left">
            {iban}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
