import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useState } from "react";
import { API_DELETE, DBRequest, useConfig } from "../../api/api";
import CardGrid from "../../components/cards/layout/CardGrid";
import PictureNameCard from "../../components/cards/PictureNameCards/PictureNameCard";
import MoneyCard from "../../components/cards/MoneyCard";
import SkeletonCard from "../../components/cards/SkeletonCard";
import AddressCard from "../../components/cards/AddressCard";
import SpeedDialMenu from "../../components/menu/SpeedDialMenu";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import useFetch from "../../api/useFetch";
import ExoDialog from "../../components/exo/ExoDialog";
import NewContact from "../../components/new/NewContact";
import { MultiContactCard } from "../../components/cards/MultiContactCard";
import PhoneIcon from "@mui/icons-material/Phone";
import { useTranslation } from "react-i18next";
import EmailIcon from "@mui/icons-material/Email";
import InfoCard from "../../components/cards/InfoCard";
import ExoAvatar from "../../components/exo/ExoAvatar";

const ExtendContact = ({ row, onUpdate, onDelete }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  const { t } = useTranslation();
  const { apiData, setApiData } = useFetch(`contacts/${row.id}`);

  // menu
  function handleDelete() {
    DBRequest({
      config,
      path: `contacts/${row.id}`,
      method: API_DELETE,
      onResponse: handleDeleteRow,
    });
  }

  const actions = [
    {
      icon: <ModeEditIcon />,
      name: t("Edit"),
      onClick: handleEdit,
    },
    { icon: <DeleteOutlineIcon />, name: t("Delete"), onClick: handleDelete },
  ];

  //edit contact
  const [open, setOpen] = useState(false);
  const [startData, setStartData] = useState(null);
  function handleEdit() {
    setStartData(apiData.data);
    setOpen(true);
  }

  function handleUpdate(data) {
    setApiData(data);
    onUpdate(data.data);
  }
  function handleDeleteRow() {
    onDelete(row);
  }

  const infos = [
    {
      label: t("Nr"),
      value: row.nr,
    },
    {
      label: t("Company"),
      value: row.company && (
        <Box className="flex items-center gap-2">
          <ExoAvatar
            picture={row.company.logo && row.company.logo.thumbnailUrl}
            type="company"
            size="20px"
          />
          {row.company.name}
        </Box>
      ),
    },
  ];

  return (
    <CardGrid>
      {!apiData ? (
        <ContactSkeleton />
      ) : (
        <>
          <SpeedDialMenu actions={actions} />
          <PictureNameCard
            data={apiData.data}
            type="contact"
            span={1}
            name={`${apiData.data.salutation} ${apiData.data.firstName} ${apiData.data.lastName}`}
          />
          <InfoCard infos={infos} span={2} />
          <MoneyCard title={t("Sales")} amount={apiData.data.salesTotal} />
          <MultiContactCard
            label={t("Phonenumbers")}
            contactArray={apiData.data.telephoneNumbers}
            nameKey="phoneType"
            valueKey="phoneNumber"
            Icon={PhoneIcon}
            linkPrefix="tel:"
          />
          <MultiContactCard
            label={t("E-Mailaddresses")}
            span={2}
            contactArray={apiData.data.emails}
            nameKey="emailType"
            valueKey="emailAddress"
            Icon={EmailIcon}
            linkPrefix="mailto:"
          />
          <AddressCard address={apiData.data.address} />
        </>
      )}
      <ExoDialog open={open} limitWidth>
        <NewContact
          fullWidth
          onClose={() => setOpen(false)}
          onUpdate={handleUpdate}
          startData={startData}
        />
      </ExoDialog>
    </CardGrid>
  );
};

const ContactSkeleton = () => {
  return (
    <>
      <SkeletonCard height={186} />
      <SkeletonCard height={186} span={2} />
      <SkeletonCard height={186} />
      <SkeletonCard height={104} />
    </>
  );
};

export default ExtendContact;
