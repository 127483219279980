import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import CardContainer from "./layout/CardContainer";
import { useNavigate } from "react-router-dom";
import useFetch from "../../api/useFetch";
import Header from "../special/Header";
import ExoTable from "../exo/ExoTable";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../label/MoneyLabel";
import ExtendOffer from "../../scenes/offers/ExtendOffer";
import { InvoiceStatus, OfferStatus, OrderStatus } from "../special/Status";
import { useEffect, useState } from "react";
import { numberToString } from "../special/numberConverter";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import ExtendOrder from "../../scenes/orders/extendOrder";
import ExtendDeliveryNote from "../../scenes/delivery-note/extendDeliveryNote";
import ExtendInvoice from "../../scenes/invoices/ExtendInvoice";

const TableCard = ({ span, id, type }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState("offers");

  const config = useConfig();

  const { apiData, setApiData } = useFetch(
    `${selectedCategory}?recipientType[eq]=${type}&recipientId[eq]=${id}`
  );

  const tableMap = {
    offers: "Offers",
    invoices: "Invoices",
    orders: "Orders",
    "delivery-notes": "Delivery Notes",
  };

  // table
  const offersColumns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.nr}</Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <OfferStatus number={row.status}></OfferStatus>, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Title"),
      key: "title",
      width: "100%",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      onClick: (row) => navigate(`/offers/${row.id}`),
    },
    {
      header: t("Sum"),
      key: "volume",
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      cell: ({ row }) => <MoneyLabel money={row.volume} />,
      sortable: true, // enable sort function for column
    },
  ];

  const invoicesColumns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.nr}</Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <InvoiceStatus number={row.status}></InvoiceStatus>, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Title"),
      key: "title",
      width: "100%",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      onClick: (row) => navigate(`/invoices/${row.id}`),
    },
    {
      header: t("Sum"),
      key: "amount",
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      className: "w-full lg:w-auto",
      cell: ({ row }) => <MoneyLabel money={row.amount} />,
      sortable: true, // enable sort function for column
    },
  ];

  const ordersColumns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.nr}</Box>
      ),
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <OrderStatus number={row.status} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Title"),
      key: "title",
      width: "100%",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      onClick: (row) => navigate(`/orders/${row.id}`),
    },
  ];

  const deliverNotesColumns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.nr}</Box>
      ),
    },
    {
      header: t("Title"),
      key: "title",
      width: "100%",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      onClick: (row) => navigate(`/delivery-notes/${row.id}`),
    },
    {
      header: t("Items"),
      key: "itemCount",
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "100px",
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      cell: ({ row }) => <Box>{numberToString(row.itemCount, 2)}</Box>, // custom xml for cells in the column. you get the row as object
    },
  ];

  const [columns, setColumns] = useState(offersColumns);

  const offersFilters = {
    filter: {
      label: t("Filter"),
      items: [
        {
          type: "dropdown",
          label: t("Status"),
          key: "state2",
          baseValue: "status[eq]=",
          active: false,
          options: [
            {
              label: t("Sent"),
              value: 0,
            },
            {
              label: t("Accepted"),
              value: 1,
            },
            {
              label: t("Finished"),
              value: 2,
            },
            {
              label: t("Rejected"),
              value: 3,
            },
            {
              label: t("Created"),
              value: 4,
            },
          ],
        },
      ],
    },
  };

  const invoicesFilters = {
    filter: {
      label: t("Filter"),
      items: [
        {
          type: "dropdown",
          label: t("Status"),
          key: "state2",
          baseValue: "status[eq]=",
          active: false,
          options: [
            {
              label: t("Created"),
              value: 0,
            },
            {
              label: t("Payed"),
              value: 1,
            },
            {
              label: t("Sent"),
              value: 2,
            },
            {
              label: t("In Arrears"),
              value: 3,
            },
            {
              label: t("Refusal to Pay"),
              value: 4,
            },
          ],
        },
      ],
    },
  };

  const ordersFilters = {
    filter: {
      label: t("Filter"),
      items: [
        {
          type: "dropdown",
          label: t("Status"),
          key: "state2",
          baseValue: "status[eq]=",
          active: false,
          options: [
            {
              label: t("Created"),
              value: 0,
            },
            {
              label: t("Finished"),
              value: 1,
            },
            {
              label: t("Cancled"),
              value: 2,
            },
            {
              label: t("Sent"),
              value: 3,
            },
          ],
        },
      ],
    },
  };

  const [filters, setFilters] = useState(offersFilters);

  function requestTableData(newCat) {
    setApiData(null);
    DBRequest({
      config,
      path: `${newCat}?recipientType[eq]=${type}&recipientId[eq]=${id}`,
      method: API_GET,
      onResponse: setApiData,
    });
  }

  function extendElementFunction({ row, onUpdate, onDelete }) {
    var extendElement = (
      <ExtendOffer row={row} onUpdate={onUpdate} onDelete={onDelete} />
    );
    if (selectedCategory == "invoices") {
      extendElement = (
        <ExtendInvoice row={row} onUpdate={onUpdate} onDelete={onDelete} />
      );
    }
    if (selectedCategory == "orders") {
      extendElement = (
        <ExtendOrder row={row} onUpdate={onUpdate} onDelete={onDelete} />
      );
    }
    if (selectedCategory == "delivery-notes") {
      extendElement = (
        <ExtendDeliveryNote row={row} onUpdate={onUpdate} onDelete={onDelete} />
      );
    }
    return extendElement;
  }

  const columnMap = {
    offers: offersColumns,
    invoices: invoicesColumns,
    orders: ordersColumns,
    "delivery-notes": deliverNotesColumns,
  };

  const filtersMap = {
    offers: offersFilters,
    invoices: invoicesFilters,
    orders: ordersFilters,
    "delivery-notes": null,
  };

  function handleChangeCategory(e) {
    const newCat = e.target.value;
    if (selectedCategory == newCat) {
      return;
    }
    setColumns(columnMap[newCat]);
    setFilters(filtersMap[newCat]);

    requestTableData(newCat);
    setSelectedCategory(newCat);
  }

  return (
    <CardContainer className="max-h-[650px] min-h-[400px] relative" span={span}>
      <>
        <Header className="pb-[40px]" title={t(tableMap[selectedCategory])} />

        <ToggleButtonGroup
          size="small"
          value={selectedCategory}
          exclusive
          onChange={handleChangeCategory}
          aria-label="Selected Table"
          className="backdrop-blur-lg left-3 top-[50px] absolute"
        >
          <ToggleButton value="offers">{t("Offers")}</ToggleButton>
          <ToggleButton value="invoices">{t("Invoices")}</ToggleButton>
          <ToggleButton value="orders">{t("Orders")}</ToggleButton>
          <ToggleButton value="delivery-notes">
            {t("Delivery Notes")}
          </ToggleButton>
        </ToggleButtonGroup>

        <ExoTable
          className="w-full"
          key={selectedCategory}
          data={apiData}
          isLoading={Boolean(!apiData)}
          columns={columns}
          filter={`recipientType[eq]=${type}&recipientId[eq]=${id}`}
          extendElement={({ row, onUpdate, onDelete }) =>
            extendElementFunction({ row, onUpdate, onDelete })
          }
          filters={filters}
        />
      </>
    </CardContainer>
  );
};

export default TableCard;
