import { useNavigate, useParams } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { API_DELETE, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import useFetch from "../../api/useFetch";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useState } from "react";
import ExoDialog from "../../components/exo/ExoDialog";
import NewContact from "../../components/new/NewContact";
import CardGrid from "../../components/cards/layout/CardGrid";
import ExoAvatar from "../../components/exo/ExoAvatar";
import InfoCard from "../../components/cards/InfoCard";
import MoneyCard from "../../components/cards/MoneyCard";
import { MultiContactCard } from "../../components/cards/MultiContactCard";
import AddressCard from "../../components/cards/AddressCard";
import SkeletonCard from "../../components/cards/SkeletonCard";
import SpeedDialMenu from "../../components/menu/SpeedDialMenu";
import PictureNameCard from "../../components/cards/PictureNameCards/PictureNameCard";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import TableCard from "../../components/cards/TableCard";

export const ShowContact = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  const { t } = useTranslation();
  const { apiData, setApiData } = useFetch(`contacts/${id}`);

  // menu
  function handleDelete() {
    DBRequest({
      config,
      path: `contacts/${id}`,
      method: API_DELETE,
      onResponse: navigate("/contacts"),
    });
  }

  const actions = [
    {
      icon: <ModeEditIcon />,
      name: t("Edit"),
      onClick: handleEdit,
    },
    { icon: <DeleteOutlineIcon />, name: t("Delete"), onClick: handleDelete },
  ];

  //edit contact
  const [open, setOpen] = useState(false);
  const [startData, setStartData] = useState(null);
  function handleEdit() {
    setStartData(apiData?.data);
    setOpen(true);
  }

  function handleUpdate(data) {
    setApiData(data);
  }

  const infos = [
    {
      label: t("Nr"),
      value: apiData?.data.nr,
    },
    {
      label: t("Company"),
      value: apiData?.data.company && (
        <Box className="flex items-center gap-2">
          <ExoAvatar
            picture={
              apiData?.data.company.logo &&
              apiData?.data.company.logo.thumbnailUrl
            }
            type="company"
            size="20px"
          />
          {apiData?.data.company.name}
        </Box>
      ),
    },
  ];

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      <CardGrid>
        {!apiData ? (
          <ContactSkeleton />
        ) : (
          <>
            <SpeedDialMenu actions={actions} />
            <PictureNameCard
              data={apiData.data}
              type="contact"
              span={1}
              name={`${apiData?.data.salutation} ${apiData?.data.firstName} ${apiData?.data.lastName}`}
            />
            <InfoCard infos={infos} span={2} />
            <MoneyCard title={t("Sales")} amount={apiData?.data.salesTotal} />
            <MultiContactCard
              label={t("Phonenumbers")}
              contactArray={apiData?.data.telephoneNumbers}
              nameKey="phoneType"
              valueKey="phoneNumber"
              Icon={PhoneIcon}
              linkPrefix="tel:"
            />
            <MultiContactCard
              label={t("E-Mailaddresses")}
              span={2}
              contactArray={apiData?.data.emails}
              nameKey="emailType"
              valueKey="emailAddress"
              Icon={EmailIcon}
              linkPrefix="mailto:"
            />
            <AddressCard address={apiData?.data.address} />
            <TableCard span={5} id={id} type={"contact"} />
          </>
        )}
        <ExoDialog open={open} limitWidth>
          <NewContact
            fullWidth
            onClose={() => setOpen(false)}
            onUpdate={handleUpdate}
            startData={startData}
          />
        </ExoDialog>
      </CardGrid>
    </Box>
  );
};

const ContactSkeleton = () => {
  return (
    <>
      <SkeletonCard height={186} />
      <SkeletonCard height={186} span={2} />
      <SkeletonCard height={186} />
      <SkeletonCard height={104} />
    </>
  );
};
