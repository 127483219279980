import { Box, Button, Typography } from "@mui/material";
import CardGrid from "../../../components/cards/layout/CardGrid";
import {
  requestTypesIcons,
  requestTypesStrings,
} from "../../../components/special/requestTypes";
import CardContainer from "../../../components/cards/layout/CardContainer";
import moment from "moment";
import { renderHours } from "../../time-tracking/worktime";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../global/theme/tokens";
import { API_PATCH, DBRequest, useConfig } from "../../../api/api";
import Feedback from "../../../components/special/Feedback";
import { useState } from "react";
import PictureNameCard from "../../../components/cards/PictureNameCards/PictureNameCard";

const ExtendTimeCompensation = ({ request, onUpdate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const values = request.values;

  const format = "DD-MM-YYYY HH:mm";

  const startDateTime = moment(values.dateTimeStart, format);
  const endDateTime = moment(values.dateTimeEnd, format);

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  return (
    <>
      <CardGrid>
        <CardContainer span={4} className="">
          <Typography
            variant="h3"
            className="flex items-center justify-center p-2 gap-2"
          >
            {requestTypesIcons[request.type]}
            {`${requestTypesStrings[request.type]} - ${request.createdAt}`}
          </Typography>
        </CardContainer>

        <PictureNameCard
          data={request.user}
          type="user"
          title="Requester"
          pictureUrl={
            request.user.picture ? request.user.picture.temporaryUrl : ""
          }
          name={`${request.user.firstName} ${request.user.lastName}`}
          mail={request.user.email}
          phone={request.user.phone}
          position={request.user.position}
        />

        <CardContainer>
          <Typography variant="h5" sx={{ color: colors.grey[600] }}>
            {startDateTime.format(format)}
          </Typography>
          <Typography variant="h5" sx={{ color: colors.grey[600] }}>
            to
          </Typography>
          <Typography variant="h5" sx={{ color: colors.grey[600] }}>
            {endDateTime.format(format)}
          </Typography>
        </CardContainer>

        <CardContainer>
          <Box className="flex justify-center items-center">
            <Box
              className="p-2 rounded-lg"
              sx={{ backgroundColor: colors.glass }}
            >
              <Typography variant="h2">
                {renderHours(endDateTime.diff(startDateTime, "minutes"), true)}{" "}
                h
              </Typography>
            </Box>
          </Box>

          <RequestController
            request={request}
            onUpdate={onUpdate}
            onFeedback={handleAlertOpen}
          />
        </CardContainer>

        <CardContainer span={4}>
          <Typography variant="p" textAlign="left" className="w-full">
            {request.comment}
          </Typography>
        </CardContainer>
      </CardGrid>

      <Feedback setState={setAlertState} state={alertState} />
    </>
  );
};

const RequestController = ({ request, onUpdate, onFeedback }) => {
  const config = useConfig();
  function handleStatusUpdate(value) {
    const data = { status: value };
    DBRequest({
      config,
      path: `employeerequests/${request.id}`,
      method: API_PATCH(data),
      onResponse: handleUpdate,
      onFeedback,
    });
  }

  function handleUpdate(response) {
    onUpdate(response.data);
  }

  if (request.status === 0) {
    return (
      <Box className="flex flex-row gap-2">
        <Button
          color="error"
          variant="contained"
          onClick={() => handleStatusUpdate(2)}
        >
          decline
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={() => handleStatusUpdate(1)}
        >
          accept
        </Button>
      </Box>
    );
  }
  if (request.status === 1) {
    return (
      <Button
        color="error"
        variant="contained"
        onClick={() => handleStatusUpdate(2)}
      >
        decline
      </Button>
    );
  }
  return (
    <Button
      color="success"
      variant="contained"
      onClick={() => handleStatusUpdate(1)}
    >
      accept
    </Button>
  );
};

export default ExtendTimeCompensation;
